import { Component } from '@angular/core';
import { BuildingState } from '@/store/building/building.state';
import { BuildingItemDto } from '@artemis-software/wr-api';
import * as L from 'leaflet';
import { mapUrls, primaryIcon } from '@pages/map-overview/map-overview.component';
import { select } from '@/utils/signals/select';
import { watch } from '@/utils/watch';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'wr-building-dashboard-map',
  templateUrl: './building-dashboard-map.component.html',
  styleUrls: ['./building-dashboard-map.component.scss'],
})
export class BuildingDashboardMapComponent {

  readonly selectedDashboardBuilding = select(BuildingState.selectedDashboardBuilding);

  private map?: L.Map;

  constructor(private nbToastrService: NbToastrService) {
    watch(this.selectedDashboardBuilding, (building) => {
      this.clearMap();
      if (building) {
        this.initializeMap(building);
        if (building.coordinates === undefined ||
          building.coordinates.latitude === undefined ||
          building.coordinates.longitude === undefined ||
          (Math.abs(building.coordinates.latitude) <= 0 &&
          Math.abs(building.coordinates.longitude) <= 0)) {
          this.nbToastrService.warning('Die Koordinaten des Gebäudes sind Fehlerhaft - Karte kann daher nicht angezeigt werden', 'Koordinaten Problem');
        }
      }
    });
  }

  private initializeMap(building: BuildingItemDto) {
    const coordinates = building.coordinates;
    if (!coordinates) {
      return;
    }
    const baseMapURl = mapUrls[0];
    this.map = L.map('map');
    L.tileLayer(baseMapURl).addTo(this.map);
    this.map.setView({
      lat: coordinates.latitude ?? 0,
      lng: coordinates.longitude ?? 0,
    }, 17);
    const layer = L.layerGroup();
    layer.addTo(this.map);
    const marker = L.marker([coordinates.latitude ?? 0, coordinates.longitude ?? 0], {
      icon: primaryIcon,
    });
    marker.addTo(layer);
  }

  private clearMap() {
    if (this.map) {
      this.map.remove();
      this.map = undefined;
    }
  }
}
