<div class="building-scan-detail-page">
  <wr-full-screen-panel [buttons]="false">
    <nb-card>
      <nb-card-header>
        <h5>Gebäudescan</h5>
        <wr-full-screen-button></wr-full-screen-button>
      </nb-card-header>
      <nb-card-body>
        <wr-building-scan-split-view [scan]="buildingScan()" readonly></wr-building-scan-split-view>
      </nb-card-body>
    </nb-card>
  </wr-full-screen-panel>
</div>
