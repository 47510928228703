import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import {
  AssessmentPresetItemDto,
  BuildingSectionService,
  CheckpointDetailDto,
  CheckpointItemDto,
  CheckpointService,
  InspectionDetailDto,
  RepairTaskMergeDto,
  RepairTaskService,
  TemplateService,
} from '@artemis-software/wr-api';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  map,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { NbDialogService, NbMenuItem, NbMenuService, NbToastrService } from '@nebular/theme';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { CheckpointDialogComponent } from '@components/dialog/checkpoint-dialog/checkpoint-dialog.component';
import {
  TemplateSelectionDialogComponent,
} from '@components/dialog/template-selection-dialog/template-selection-dialog.component';
import { InspectionFormWrapper } from '../inspection-form';
import { createInspectionEntryForm, getCheckpointString, InspectionEntryFormType } from '../inspection-util';
import {
  ApplyFromOtherSectionDialogComponent,
} from '@components/dialog/apply-from-other-section-dialog/apply-from-other-section-dialog.component';
import {
  DuplicateSectionDialogComponent,
  DuplicateSectionDialogResult,
} from '@components/dialog/duplicate-section-dialog/duplicate-section-dialog.component';
import { randomId } from '@/utils/random-utils';
import {
  InspectionEntryFormComponent,
} from '@pages/inspection-detail-page-v2/inspection-entries/inspection-entry-form/inspection-entry-form.component';
import { tryWithLoading } from '@/utils/async-utils';
import {
  RepairTaskEditDialogComponent,
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-edit-dialog/repair-task-edit-dialog.component';
import { Location } from '@angular/common';
import { isAdmin } from '@/utils/admin-utils';
import { InspectionContext } from '@pages/inspection-detail-page-v2/inspection.context';

export type InspectionEntryGroup = {
  checkpointGroupId: string;
  checkpointGroupName: string;
  checkpointGroupNumber: number;
  buildingEquipmentRelated: boolean;
  entries: InspectionEntryFormType[];
}

const checkpointDetailToItem = (detail: CheckpointDetailDto): CheckpointItemDto => {
  return {
    id: detail.id,
    name: detail.name,
    number: detail.number,
    checkpointGroupId: detail.checkpointGroup.id,
    checkpointGroupName: detail.checkpointGroup.name,
    checkpointGroupNumber: detail.checkpointGroup.number,
    buildingEquipmentRelated: detail.buildingEquipmentRelated,
    deprecated: detail.deprecated,
    weight: detail.weight,
  };
};

type MenuItemWithAction = NbMenuItem & { data: { action: () => unknown } };

type ContextMenu = {
  items: MenuItemWithAction[];
  tag: string;
}

@Component({
  selector: 'wr-inspection-entries[formWrapper][inspection]',
  templateUrl: './inspection-entries.component.html',
  styleUrls: ['./inspection-entries.component.scss'],
})
export class InspectionEntriesComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly inspectionContext = inject(InspectionContext);

  isAdmin = isAdmin();

  readonly getCheckpointString = getCheckpointString;

  @ViewChild(InspectionEntryFormComponent)
  entryFormComponent?: InspectionEntryFormComponent;

  @Input()
  formWrapper!: InspectionFormWrapper;
  @Input()
  inspection!: InspectionDetailDto;
  @Input()
  inspectionEntryId = signal('');
  @Output()
  importing$ = new BehaviorSubject<boolean>(false);
  @Output()
  repairTaskCreated = new EventEmitter<RepairTaskMergeDto>();

  selectedSection$ = new BehaviorSubject<BuildingSectionDetailDto | undefined>(undefined);
  inspectionEntriesGrouped$ = new BehaviorSubject<InspectionEntryGroup[]>([]);
  selectedEntry$ = new BehaviorSubject<InspectionEntryFormType | undefined>(undefined);
  search$ = new BehaviorSubject<string>('');
  destroy$ = new Subject<void>();
  selectedGroupId?: string;
  loading$ = new BehaviorSubject<boolean>(false);

  sectionContextMenu: ContextMenu = {
    items: [],
    tag: randomId(),
  };
  entryContextMenu: ContextMenu = {
    items: [],
    tag: randomId(),
  };
  entriesThatCanBeAppliedFromOtherSections$ = new BehaviorSubject<InspectionEntryFormType[]>([]);


  get canImportTemplate() {
    return !this.formWrapper.form?.controls.usedTemplateId?.value && this.formWrapper.enabled;
  }

  get hasAnyPendingAttachments(): boolean {
    return this.entryFormComponent?.hasAnyPendingAttachments ?? false;
  }

  constructor(
    private readonly nbMenuService: NbMenuService,
    private readonly dialogService: NbDialogService,
    private readonly checkpointService: CheckpointService,
    private readonly nbToastrService: NbToastrService,
    private readonly nbDialogService: NbDialogService,
    private readonly templateService: TemplateService,
    private readonly repairTaskService: RepairTaskService,
    private readonly buildingSectionService: BuildingSectionService,
    private readonly location: Location,
  ) {
  }

  ngOnInit(): void {
    this.selectedEntry$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(async () => {
      this.entriesThatCanBeAppliedFromOtherSections$.next(await this.getEntriesThatCanBeAppliedFromOtherSections());
    });

    this.nbMenuService.onItemClick().pipe(
      takeUntil(this.destroy$),
      filter(({ tag }) => tag === this.entryContextMenu.tag || tag === this.sectionContextMenu.tag),
    ).subscribe((event) => {
      event.item.data?.action?.();
    });

    this.selectedSection$.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
    ).subscribe(() => {
      const entryId = this.inspectionEntryId();
      const selectedSection = this.selectedSection$.value;
      if (entryId && selectedSection) {
        const entry = this.formWrapper.controls?.inspectionEntries.controls.find(entry => entry.controls.id.value === entryId);
        if (entry?.controls.buildingSectionId.value !== selectedSection.id) {
          this.selectedEntry$.next(undefined);
          this.location.replaceState(`/inspection/${this.inspection.id}/entries`);
        }
      }
    });

    this.formWrapper.enabled$.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
    ).subscribe(editMode => {
      if (!editMode) {
        this.selectedEntry$.next(undefined);
      }
    });

    combineLatest([
      this.selectedEntry$,
      this.formWrapper.enabled$,
    ]).pipe(
      takeUntil(this.destroy$),
    ).subscribe(([entry, editMode]) => {
      this.updateSectionContextMenu(editMode);
      this.updateEntryContextMenu(entry, editMode);
    });

    const inspectionEntries$ = this.formWrapper.form$.pipe(
      takeUntil(this.destroy$),
      filter(form => !!form),
      map(form => form!.controls.inspectionEntries),
      switchMap(inspectionEntries => inspectionEntries.valueChanges.pipe(
        startWith(inspectionEntries.value),
        takeUntil(this.destroy$),
      )),
      shareReplay(1),
    );

    combineLatest([
      this.selectedSection$,
      inspectionEntries$,
      this.search$,
    ]).pipe(
      debounceTime(100),
      takeUntil(this.destroy$),
    ).subscribe(async ([section, , search]) => {
      this.updateDisplayedEntries(search, section);
    });
  }

  ngAfterViewInit(): void {
    if (this.inspectionEntryId() !== '') {
      this.navigateToEntry(this.inspectionEntryId());
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateDisplayedEntries(search: string, section?: BuildingSectionDetailDto) {
    const inspectionEntries = this.formWrapper.form$.value?.controls.inspectionEntries;

    if (!inspectionEntries) {
      throw new Error('No inspection entries');
    }

    const entryControls = inspectionEntries.controls;
    const entriesInSection = section
      ? entryControls.filter(entry => entry.controls.buildingSectionId?.value === section.id)
      : [];
    const map = new Map<number, InspectionEntryGroup>();
    for (const entry of entriesInSection) {
      const controls = entry.controls;
      const checkpointGroupNumber = controls.checkpointGroupNumber.value ?? 0;
      const checkpointGroupName = controls.checkpointGroupName.value ?? '';
      const checkpointGroupId = controls.checkpointGroupId.value ?? '';
      const buildingEquipmentRelated = controls.buildingEquipmentRelated.value ?? false;
      const entryString = getCheckpointString(entry);

      const filterMatch = entryString.toLowerCase().includes(search.toLowerCase());
      if (filterMatch) {
        const group: InspectionEntryGroup = map.get(checkpointGroupNumber) ?? {
          checkpointGroupNumber,
          checkpointGroupName,
          checkpointGroupId,
          buildingEquipmentRelated,
          entries: [] as InspectionEntryFormType[],
        };
        group.entries.push(entry);
        map.set(checkpointGroupNumber, group);
      }
    }
    const groups = Array.from(map.values());
    groups.sort((a, b) => a.checkpointGroupNumber - b.checkpointGroupNumber);
    groups.forEach(group =>
      group.entries.sort((a, b) => {
        const checkpointNumberA = a.controls.checkpointNumber.value ?? 0;
        const checkpointNumberB = b.controls.checkpointNumber.value ?? 0;
        return checkpointNumberA - checkpointNumberB;
      }),
    );
    this.inspectionEntriesGrouped$.next(groups);
  }

  async duplicateCurrent(): Promise<void> {
    console.assert(!!this.selectedEntry$.value, 'No entry selected');
    console.assert(!!this.selectedSection$.value, 'No section selected');
    await this.duplicateEntry(this.selectedEntry$.value!, this.selectedSection$.value!);
  }

  async duplicateEntry(entry: InspectionEntryFormType, targetSection: BuildingSectionDetailDto): Promise<void> {
    const checkpoint = await firstValueFrom(this.checkpointService.findById(entry.value.checkpointId!));
    this.addInspectionEntryByCheckpoint(checkpointDetailToItem(checkpoint), targetSection.id);
    this.nbToastrService.success('Prüfpunkt dupliziert', 'Erfolg');
  }

  async changeCheckpoint(): Promise<void> {
    const currentSection = this.selectedSection$.value?.id;
    console.assert(!!currentSection, 'No entry selected');

    try {
      const checkpointId = await firstValueFrom(this.dialogService.open(CheckpointDialogComponent, {
        context: {
          selectedCheckpointGroupId: this.selectedGroupId,
          selectedCheckpointId: this.selectedEntry$.value?.value?.checkpointId ?? undefined,
        },
      }).onClose);
      if (checkpointId) {
        const controls = this.selectedEntry$.value?.controls;
        const checkpoint = await firstValueFrom(this.checkpointService.findById(checkpointId));
        controls?.checkpointId.setValue(checkpoint.id);
        controls?.checkpointName.setValue(checkpoint.name);
        controls?.checkpointNumber.setValue(checkpoint.number);
        controls?.checkpointGroupId.setValue(checkpoint.checkpointGroup.id);
        controls?.checkpointGroupName.setValue(checkpoint.checkpointGroup.name);
        controls?.checkpointGroupNumber.setValue(checkpoint.checkpointGroup.number);
        this.selectedGroupId = checkpoint.checkpointGroup.id;
        this.nbToastrService.success('Prüfpunkt geändert', 'Erfolg');
      }
    } catch (err) {
      console.error(err);
      this.nbToastrService.danger('Fehler beim auswählen eines Prüfpunktes', 'Fehler');
    }
  }

  async delete(): Promise<void> {
    if (!await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Prüfpunktzeile löschen',
        message: 'Bist du sicher, dass du die Prüfpunktzeile löschen willst?',
      },
    }).onClose)) {
      return;
    }
    const selected = this.selectedEntry$.value;
    const cid = selected?.controls.cid.value;
    if (cid) {
      const inspectionEntries = this.formWrapper.controls?.inspectionEntries;

      if (!inspectionEntries) {
        throw new Error('No inspection entries');
      }

      const index = inspectionEntries.controls.findIndex(entry => entry.controls.cid.value === cid);
      if (index >= 0) {
        inspectionEntries.removeAt(index);
        this.selectedEntry$.next(undefined);
      }
    }
  }

  hide(): void {
    this.selectedEntry$.next(undefined);
  }

  async showAddInspectionEntryDialog(): Promise<void> {
    const currentSection = this.selectedSection$.value?.id;

    if (!currentSection) {
      throw new Error('No section selected');
    }

    try {
      const checkpointId = await firstValueFrom(this.dialogService.open(CheckpointDialogComponent, {
        context: {
          selectedCheckpointGroupId: this.selectedGroupId,
        },
      }).onClose);
      if (checkpointId) {
        const checkpoint = await firstValueFrom(this.checkpointService.findById(checkpointId));
        this.addInspectionEntryByCheckpoint(checkpointDetailToItem(checkpoint), currentSection);
        this.nbToastrService.success('Prüfpunktzeile hinzugefügt', 'Erfolg');
      }
    } catch (err) {
      console.error(err);
      this.nbToastrService.danger('Fehler beim auswählen eines Prüfpunktes', 'Fehler');
    }
  }

  addInspectionEntryByCheckpoint(checkpoint: CheckpointItemDto, targetSectionId: string): void {
    const inspectionEntries = this.formWrapper.controls?.inspectionEntries;

    if (!inspectionEntries) {
      throw new Error('No inspection entries');
    }

    const formGroup = createInspectionEntryForm(checkpoint, {
      targetSectionId,
      standardAssessment: checkpoint.standardAssessment,
    });

    inspectionEntries.push(formGroup);
    inspectionEntries.markAsDirty();
    this.formWrapper.updateValueAndValidity({ emitEvent: false });
    this.selectedEntry$.next(formGroup);
    this.selectedGroupId = checkpoint.checkpointGroupId;
  }

  async importTemplate(): Promise<void> {
    const templateId = await firstValueFrom(this.nbDialogService.open(TemplateSelectionDialogComponent).onClose);
    await tryWithLoading(this.importing$, async () => {
      if (templateId) {
        this.formWrapper.form?.controls.usedTemplateId.setValue(templateId);
        const building = this.formWrapper.controls?.building.value;

        if (!building) {
          throw new Error('Kein Gebäude ausgewählt.');
        }

        const template = await firstValueFrom(this.templateService.findById(templateId));
        const templateCheckpoints = [...template.templateCheckpoints]
          .sort((a, b) => a.orderSequence - b.orderSequence);

        for (const templateCheckpoint of templateCheckpoints) {
          for (const section of building.sections) {
            this.addInspectionEntryByCheckpoint(templateCheckpoint.checkpoint, section.id);
          }
        }
      }
    });
  }

  private updateSectionContextMenu(editMode: boolean): void {
    const items: MenuItemWithAction[] = [];

    if (editMode) {
      items.push(...[
        {
          title: 'Gebäudeteil duplizieren',
          icon: { pack: 'eva', icon: 'diagonal-arrow-right-up-outline' },
          data: {
            action: () => this.duplicateSection(),
          },
        },
        {
          title: 'Leere Prüfpunktzeilen löschen',
          icon: { pack: 'eva', icon: 'trash-2-outline' },
          data: { action: () => this.showDeleteEmptyInspectionsEntriesDialog() },
        },
      ]);
    }
    this.sectionContextMenu.items = items;
  }

  private updateEntryContextMenu(currentEntry: InspectionEntryFormType | undefined, editMode: boolean): void {
    const menu: MenuItemWithAction[] = [];

    const selectedEntryId = currentEntry?.value?.id;
    if (!this.isAdmin()) {
      return;
    }

    if (selectedEntryId) {
      if (!this.inspectionContext.hasRepairTask(selectedEntryId)) {
        menu.push({
          title: 'Reparaturauftrag erstellen',
          icon: { pack: 'eva', icon: 'radio-button-on-outline' },
          data: { action: () => this.createRepairTask(selectedEntryId) },
        });
      }
    }

    if (editMode) {
      menu.push(
        {
          title: 'Duplizieren',
          icon: { pack: 'eva', icon: 'copy' },
          data: { action: () => this.duplicateCurrent() },
        },
        {
          title: 'Prüfpunkt ändern',
          icon: { pack: 'eva', icon: 'repeat' },
          data: { action: () => this.changeCheckpoint() },
        },
        {
          title: 'Löschen',
          icon: { pack: 'eva', icon: 'trash-2-outline' },
          data: { action: () => this.delete() },
        },
      );
    }

    menu.push({
      title: 'Ausblenden',
      icon: { pack: 'eva', icon: 'eye-off-outline' },
      data: { action: () => this.hide() },
    });

    this.entryContextMenu.items = menu;
  }


  async applyEntryFromOtherSection(): Promise<void> {
    const entries = this.entriesThatCanBeAppliedFromOtherSections$.value;
    const selected = await this.showApplyEntryFromOtherSectionDialog(entries);
    if (selected) {
      const controls = this.selectedEntry$.value?.controls;
      controls?.predefinedAssessment?.setValue(selected.controls.predefinedAssessment?.value);
      controls?.action?.setValue(selected.controls.action?.value);
      controls?.note?.setValue(selected.controls.note?.value);
      controls?.deadline?.setValue(selected.controls.deadline?.value);
      this.selectedEntry$.value?.markAsDirty();
      this.selectedEntry$.value?.updateValueAndValidity({ emitEvent: false });
    }
  }

  private findEntriesWithCheckpointInOtherSection(checkpointId: string, currentSectionId: string): InspectionEntryFormType[] {
    const allEntries = this.formWrapper.controls?.inspectionEntries;
    return allEntries?.controls.filter(entry =>
      entry.controls.checkpointId?.value === checkpointId &&
      entry.controls.buildingSectionId?.value !== currentSectionId &&
      entry.controls.predefinedAssessment.value,
    ) ?? [];
  }

  private async showApplyEntryFromOtherSectionDialog(entries: InspectionEntryFormType[]): Promise<InspectionEntryFormType | undefined> {
    if (entries?.length) {
      return await firstValueFrom(this.nbDialogService.open(ApplyFromOtherSectionDialogComponent, {
        context: {
          building: this.formWrapper.controls?.building.value,
          entries,
          checkpointString: getCheckpointString(this.selectedEntry$.value!),
        },
      }).onClose);
    }
    return undefined;
  }

  private async getEntriesThatCanBeAppliedFromOtherSections(): Promise<InspectionEntryFormType[]> {
    const controls = this.selectedEntry$.value?.controls;
    const predefinedAssessment = controls?.predefinedAssessment.value;
    if (predefinedAssessment) {
      return [];
    }
    const checkpointId = controls?.checkpointId?.value;
    const sectionId = this.selectedSection$.value?.id;

    if (!checkpointId || !sectionId) {
      return [];
    }

    return this.findEntriesWithCheckpointInOtherSection(checkpointId, sectionId);
  }

  async deleteGroup(groupToDelete: InspectionEntryGroup): Promise<void> {
    const inspectionEntries = this.formWrapper.controls?.inspectionEntries;

    if (!inspectionEntries) {
      throw new Error('No inspection entries');
    }

    const entries = inspectionEntries.controls;
    const entriesToDelete = entries.filter(entry =>
      entry.value.checkpointGroupId === groupToDelete.checkpointGroupId &&
      entry.value.buildingSectionId === this.selectedSection$.value?.id,
    );

    console.assert(entriesToDelete.length > 0, 'No entries to delete');

    if (await firstValueFrom(this.nbDialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Gruppe löschen?',
        message: entriesToDelete.length > 1
          ? `Alle ${entriesToDelete.length} Prüfpunktzeilen in dieser Gruppe werden gelöscht.`
          : '1 Prüfpunktzeile wird gelöscht.',
      },
    }).onClose)) {
      for (const entry of entriesToDelete) {
        inspectionEntries.removeAt(inspectionEntries.controls.indexOf(entry));
      }
      inspectionEntries.markAsDirty();
      this.formWrapper.updateValueAndValidity({ emitEvent: false });
      this.selectedEntry$.next(undefined);
    }
  }

  private async duplicateSection(): Promise<void> {
    const inspectionEntries = this.formWrapper.controls?.inspectionEntries;

    if (!inspectionEntries) {
      throw new Error('No inspection entries');
    }

    const entries = inspectionEntries.controls;
    const entriesInSection = entries.filter(entry => entry.value.buildingSectionId === this.selectedSection$.value?.id);
    const result = await firstValueFrom(this.nbDialogService.open(DuplicateSectionDialogComponent, {
      context: {
        entries: entriesInSection,
        building: this.formWrapper.controls?.building.value,
      },
    }).onClose) as DuplicateSectionDialogResult;
    if (result) {
      result.entriesToDuplicate.forEach(entry => {
        this.duplicateEntry(entry, result.targetBuildingSection);
      });
      this.nbToastrService.success('Prüfpunktzeilen dupliziert', 'Erfolg');
      this.selectedSection$.next(result.targetBuildingSection);
      this.selectedEntry$.next(undefined);
    }
  }

  async showDeleteEmptyInspectionsEntriesDialog(): Promise<void> {
    const inspectionEntries = this.formWrapper.controls?.inspectionEntries;
    if (!inspectionEntries) {
      throw new Error('No inspection entries');
    }
    const { emptyEntries, emptyEntriesCount } = this.findEmptyOrNullEntries(inspectionEntries.controls as any[]);
    const userConfirmed = await firstValueFrom(
      this.nbDialogService.open(DeleteDialogComponent, {
        context: {
          title: 'Leere Prüfzeilen löschen',
          message: `Bist du sicher, dass du ${emptyEntriesCount} leere Einträge löschen willst?`,
        },
      }).onClose,
    );

    if (userConfirmed) {
      emptyEntries.forEach(entry => {
        const index = inspectionEntries.controls.indexOf(entry);
        if (index !== -1) {
          inspectionEntries.removeAt(index);
        }
      });
    }
  }

  private findEmptyOrNullEntries(entries: any[]) {
    const isNullOrEmtpy = (value: string | AssessmentPresetItemDto | null | undefined) => value === null || value === undefined || value === '';
    const emptyEntries = entries.filter(entry =>
      isNullOrEmtpy(entry.value.deadline) &&
      isNullOrEmtpy(entry.value.predefinedAssessment) &&
      isNullOrEmtpy(entry.value.action) &&
      isNullOrEmtpy(entry.value.note),
    );
    const emptyEntriesCount = emptyEntries.length;
    return { emptyEntries, emptyEntriesCount };
  }

  private async createRepairTask(entryId: string) {
    const repairTask = await firstValueFrom(this.repairTaskService.findByInspectionEntryId(entryId));
    if (repairTask) {
      this.nbToastrService.info('Es gibt für diesen Prüfpunkt bereits einen Reparaturauftrag!', 'Reparaturauftrag vorhanden!');
      return;
    }

    const ref = this.nbDialogService.open(RepairTaskEditDialogComponent, {
      autoFocus: false,
    });

    const checkpointId = this.selectedEntry$.value?.value.checkpointId;
    if (!checkpointId) {
      throw new Error('No checkpoint id');
    }

    const checkpoint = await firstValueFrom(this.checkpointService.findById(checkpointId));

    const value = this.selectedEntry$.value?.value;
    ref.componentRef.instance.initDialog(null, checkpoint.contractors, entryId, value?.predefinedAssessment?.text, value?.note);
    const result = await firstValueFrom(ref.onClose) as RepairTaskMergeDto | undefined;

    if (result) {
      await tryWithLoading(this.loading$, async () => {
        await firstValueFrom(this.repairTaskService.merge(result));
        this.nbToastrService.success('Reparaturauftrag erstellt', 'Erfolg');
        this.repairTaskCreated.emit();
      });
    }
  }

  async navigateToEntry(entryId: string): Promise<void> {
    const allEntries = this.formWrapper.controls?.inspectionEntries.controls;
    const targetEntry = allEntries?.find(entry => entry.controls.id.value === entryId);

    if (!targetEntry) {
      this.nbToastrService.danger('Fehler beim Navigieren zu Prüfpunktzeile', 'Fehler');
      throw new Error('Entry not found');
    }

    const sectionId = targetEntry.controls.buildingSectionId.value;
    const groupId = targetEntry.controls.checkpointGroupId.value;

    if (!sectionId) {
      this.nbToastrService.danger('Fehler beim Navigieren zu Prüfpunktzeile', 'Fehler');
      throw new Error('No section id');
    }

    const targetSection = await firstValueFrom(this.buildingSectionService.findById(sectionId));
    if (targetSection) {
      this.selectedSection$.next(targetSection);
      this.selectedGroupId = groupId;
      this.selectedEntry$.next(targetEntry);
    }
  }

  @HostListener('window:keydown.PageUp', ['$event'])
  previousEntry(event: KeyboardEvent) {
    this.jumpInspectionEntry(-1);
    event.preventDefault();
  }

  @HostListener('window:keydown.PageDown', ['$event'])
  nextEntry(event: KeyboardEvent) {
    this.jumpInspectionEntry(1);
    event.preventDefault();
  }

  private jumpInspectionEntry(indexOffset: number) {
    const entries = this.flattenInspectionEntries();
    const currentEntry = this.selectedEntry$.value;
    const currentIndex = entries.findIndex((entry) => entry.value.cid === currentEntry?.value.cid);
    const nextIndex = currentIndex + indexOffset;
    if (nextIndex >= 0 && nextIndex < entries.length) {
      const entry = entries[nextIndex];
      this.selectedEntry$.next(entry);
      this.selectedGroupId = entry.value.checkpointGroupId;
      this.scrollToEntry();
    }
  }

  private flattenInspectionEntries(): InspectionEntryFormType[] {
    return this.inspectionEntriesGrouped$.value.flatMap(group => group.entries);
  }

  private scrollToEntry() {
    const cid = this.selectedEntry$.value?.value.cid;
    if (cid) {
      const element = document.getElementById(cid);
      if (element) {
        element.scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }
  }
}
