import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { EquipmentDetailDto, EquipmentMergeDto, EquipmentService } from '@artemis-software/wr-api';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { LoadAllEquipments } from '@/store/equipment/equipment.action';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { PendingChanges } from '@guards/pending-changes.guard';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  Attachment, attachmentDetailToAttachmentReferenceDto, attachmentFromDetail,
  attachmentsFromDetails,
  attachmentToMerge,
} from '@shared/form-controls/attachment-upload/attachment-utils';
import { createForm } from '@sonofabit/ng-core';
import { tryWithLoading } from '@/utils/async-utils';

@Component({
  selector: 'wr-equipment-detail-page',
  templateUrl: './equipment-detail-page.component.html',
  styleUrls: ['./equipment-detail-page.component.scss'],
})
export class EquipmentDetailPageComponent implements PendingChanges {
  isEditing = false;
  firstSubmit = false;
  form?: ReturnType<typeof this.createFormFromDetail>;

  loading$ = new BehaviorSubject(false);

  constructor(
    route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: NbDialogService,
    private readonly equipmentService: EquipmentService,
    private readonly nbToastrService: NbToastrService,
  ) {
    route.paramMap.subscribe(async (params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.form = this.createFormFromDetail(null);
        } else {
          this.equipmentService.findById(id).subscribe((equipment) => {
            this.form = this.createFormFromDetail(equipment);
            this.form?.disable();
          });
        }
      } else {
        await this.router.navigate(['equipments']);
      }
    });
  }

  createFormFromDetail(detail: EquipmentDetailDto | null) {
    return createForm(({ field }) => {
      return {
        id: field({ value: detail?.id }),
        name: field({ value: detail?.name, validators: [Validators.required] }),
        description: field({ value: detail?.description }),
        color: field({ value: detail?.color, validators: [Validators.required] }),
        maintenanceInterval: field({ value: detail?.maintenanceInterval, validators: [Validators.required] }),
        equipmentType: field({ value: detail?.equipmentType, validators: [Validators.required] }),
        iconReference: field({
          value: attachmentFromDetail(detail?.icon),
        }),
        attachments: field({
          value: attachmentsFromDetails(detail?.attachments ?? []),
        }),
        collectionIds: field({
          value: detail?.customFieldCollections
            .map((equipmentCollection) => equipmentCollection.collection.id),
        }),
      };
    });
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.nbToastrService.danger('TGA Typ kann nicht gespeichert werden, es gibt noch Fehler.', 'TGA Typ');
      return;
    }

    await tryWithLoading(this.loading$, async () => {
      const equipmentDto = {
        ...this.form?.value,
        iconReference: attachmentDetailToAttachmentReferenceDto(this.form?.value.iconReference),
        attachments: this.form?.value?.attachments?.map((dto: Attachment) => attachmentToMerge(dto)),
      } as EquipmentMergeDto;
      const saved = await firstValueFrom(this.equipmentService.merge(equipmentDto));
      this.form?.get('id')?.setValue(saved.id);
      await this.reloadForm();
      await this.router.navigate(['equipment', saved.id]);
      this.store.dispatch(new LoadAllEquipments());
      this.nbToastrService.success('TGA Typ wurde erfolgreich gespeichert', 'TGA Typ');
    });
  }

  async onDelete(): Promise<void> {
    if (await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'TGA Typ löschen',
        message: 'Bist du sicher, dass du die TGA Typ löschen willst?',
      },
    }).onClose) && this.form?.value.id) {
      await firstValueFrom(this.equipmentService.deleteById(this.form?.value.id));
      this.store.dispatch(new LoadAllEquipments());
      await this.router.navigate(['equipments']);
      this.nbToastrService.success('TGA Typ wurde erfolgreich gelöscht', 'TGA Typ');
    }
  }

  async cancel(): Promise<void> {
    await this.reloadForm();
  }

  async reloadForm(): Promise<void> {
    await tryWithLoading(this.loading$, async () => {
      const id = this.form?.value.id;
      if (id) {
        const equipment = await firstValueFrom(this.equipmentService.findById(id));
        this.form = this.createFormFromDetail(equipment);
      }
    });
    this.isEditing = false;
    this.form?.disable();
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['equipments']);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }
}
