<div class="search">
  <wr-search-bar [search]="filterText()" (searchChange)="filterText.set($event)"></wr-search-bar>
  <button nbButton ghost [nbPopover]="filterPopover" nbPopoverPlacement="bottom">
    <nb-icon pack="eva" icon="funnel-outline"></nb-icon>
  </button>
</div>

<div [nbSpinner]="loading">
  <div class="tree-view noselect">
    <wr-building-scan-equipment-tree-view-entry
      *ngFor="let buildingEquipment of filteredBuildingEquipments(); trackBy: trackByFn"
      [buildingEquipment]="buildingEquipment"
      [readonly]="readonly">
    </wr-building-scan-equipment-tree-view-entry>
  </div>
</div>

<ng-template #filterPopover>
  <div class="filter-popover">
    <nb-checkbox [checked]="onlyShowWithPOI()" (checkedChange)="onlyShowWithPOI.set($event)">
      Nur Einträge mit POI anzeigen
    </nb-checkbox>
  </div>
</ng-template>
