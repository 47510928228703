import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';
import { FullScreenService } from '@components/full-screen-panel/full-screen.service';

@Component({
  selector: 'wr-full-screen-panel',
  templateUrl: './full-screen-panel.component.html',
  styleUrls: ['./full-screen-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FullScreenService],
})
export class FullScreenPanelComponent {
  readonly fullScreenService = inject(FullScreenService);

  @Input()
  buttons: boolean = true;

  get fullScreen() {
    return this.fullScreenService.isFullScreen;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.exitFullScreen();
    }
  }

  exitFullScreen() {
    this.fullScreen.set(false);
  }

  enterFullScreen() {
    this.fullScreen.set(true);
  }
}
