import { effect, isSignal, signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';
import { Loader, unwrapLoader } from './loader';

export type CleanupHandler = () => void;

export interface AsyncComputedOptions {
  isLoading?: WritableSignal<boolean> | Loader;
  onError?: (error: unknown) => void;
}

export type AsyncComputed<T> = WritableSignal<T> & {
  reload: () => void;
};

export function asyncComputed<T>(
  fn: (onCleanup: (handler: CleanupHandler) => void) => Promise<T> | Observable<T> | T,
): AsyncComputed<T | undefined>;

export function asyncComputed<T>(
  fn: (onCleanup: (handler: CleanupHandler) => void) => Promise<T> | Observable<T> | T,
  defaultValue: T,
  options?: AsyncComputedOptions,
): AsyncComputed<T>;

export function asyncComputed<T>(
  fn: (onCleanup: (handler: CleanupHandler) => void) => Promise<T> | Observable<T> | T,
  defaultValue: T,
  isLoading?: WritableSignal<boolean> | Loader,
): AsyncComputed<T>;

/**
 * Creates a reactive signal that computes an asynchronous value.
 * @param fn A function that returns a Promise, Observable or a value.
 * @param defaultValue The default value of the signal.
 * @param optionsOrLoading An object containing options or a writable signal indicating whether the computation is loading.
 * @returns A reactive signal that computes the asynchronous value.
 */
export function asyncComputed(
  fn: (
    onCleanup: (handler: CleanupHandler) => void,
  ) => Promise<unknown> | Observable<unknown> | unknown,
  defaultValue?: unknown,
  optionsOrLoading?: WritableSignal<boolean> | Loader | AsyncComputedOptions,
): AsyncComputed<unknown> {
  const value = signal(defaultValue);
  let cleanup: (() => void)[] = [];
  const addCleanupHandler = (handler: CleanupHandler): void => {
    cleanup.push(handler);
  };

  let count = 0;

  const isLoading = unwrapLoader(
    optionsOrLoading
      ? isSignal(optionsOrLoading)
        ? optionsOrLoading
        : optionsOrLoading.isLoading
      : undefined,
  );
  const options = optionsOrLoading && !isSignal(optionsOrLoading) ? optionsOrLoading : undefined;

  const reloadSignal = signal(0);

  effect(
    () => {
      reloadSignal()

      if (cleanup.length > 0) {
        cleanup.forEach((handler) => handler());
        cleanup = [];
      }

      const currentCount = ++count;

      const result = fn(addCleanupHandler);

      if (result instanceof Observable) {
        isLoading?.set(true);

        const subscription = result.subscribe({
          next: (newValue) => {
            if (count !== currentCount) return;
            value.set(newValue);
            isLoading?.set(false);
          },
          error: (err) => {
            isLoading?.set(false);
            options?.onError?.(err);
          },
        });

        cleanup.push(() => subscription.unsubscribe());
      } else if (result instanceof Promise) {
        isLoading?.set(true);

        result
          .then((newValue) => {
            if (count !== currentCount) return;
            value.set(newValue);
            isLoading?.set(false);
          })
          .catch((err) => {
            isLoading?.set(false);
            options?.onError?.(err);
          });
      } else {
        value.set(result);
      }
    },
    { allowSignalWrites: true },
  );

  return Object.assign(value, {
    reload: () => {
      reloadSignal.set(reloadSignal() + 1);
    }
  });
}
