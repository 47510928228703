<div class="title">
  <nb-icon icon="cube" pack="eva"></nb-icon>
  <span [nbTooltip]="scan.name">
      {{ scan.name }}
    </span>
</div>
<div class="date">{{ scan.createdAt | date: 'dd.MM.yyyy' }}</div>
<div class="actions">
  <a nbButton status="primary" size="small" [routerLink]="['/scan', scan.id]">
    <nb-icon icon="arrow-forward-outline" pack="eva"></nb-icon>
  </a>
</div>
