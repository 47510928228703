import { Component } from '@angular/core';
import { EquipmentFilterDto, EquipmentItemDto, EquipmentService } from '@artemis-software/wr-api';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-equipment-overview-page',
  templateUrl: './equipment-overview-page.component.html',
  styleUrls: ['./equipment-overview-page.component.scss'],
})
export class EquipmentOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadEquipments = (filter: EquipmentFilterDto) => this.equipmentService.findAll(filter);
  readonly getCount = (filter: EquipmentFilterDto) => this.equipmentService.getCount(filter);

  constructor(private readonly router: Router,
              private readonly equipmentService: EquipmentService,
              private readonly formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      equipmentType: this.formBuilder.control<string | null>(null),
      maintenanceInterval: this.formBuilder.control<string | null>(null),
    });
  }

  navigateToEquipment(equipment: EquipmentItemDto): void {
    this.router.navigate(['equipment', equipment.id]);
  }

  add(): void {
    this.router.navigate(['equipment', 'new']);
  }

  getIcon(equipment: EquipmentItemDto): string {
    return equipment.icon?.presignedUrl ?? '';
  }
}
