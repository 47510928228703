import { Component, Input } from '@angular/core';

@Component({
  selector: 'wr-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input()
  value = 0;
}
