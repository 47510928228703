<wr-new-data-table headerText="Prüfpunktübersicht"
                   localStorageIdentifier="checkpoint-overview"
                   [loadItemsFunction]="loadCheckpoints"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToCheckpoint($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="checkpointGroup">Prüfpunktgruppe</label>
          <wr-checkpoint-group-select id="checkpointGroup" formControlName="checkpointGroupId"
                                      [showEmptyOption]="true"></wr-checkpoint-group-select>
        </div>
        <div class="filter-control-group">
          <label class="label" for="deprecated">Überholte Prüfpunkte anzeigen</label>
          <nb-select id="deprecated" formControlName="deprecated">
            <nb-option value="false">Nicht überholte</nb-option>
            <nb-option value="true">Überholte</nb-option>
          </nb-select>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-checkpoint
    key="number"
    display="Nummer">
    <div *ngIf="checkpoint.number">
      {{getCheckpointNumber(checkpoint)}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-checkpoint
    key="groupName"
    display="Gruppe">
    <div *ngIf="checkpoint">
      {{getCheckpointGroupName(checkpoint)}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-checkpoint
    key="name"
    display="Name">
    <div *ngIf="checkpoint.name">
      {{checkpoint.name}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-checkpoint
    key="weight"
    display="Gewicht">
    <div *ngIf="checkpoint.weight">
      <wr-checkpoint-weight [weight]="checkpoint.weight"></wr-checkpoint-weight>
    </div>
  </ng-template>
</wr-new-data-table>
