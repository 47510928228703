import { ChangeDetectionStrategy, Component, inject, OnDestroy, signal } from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import { routeParam } from '@/utils/signals/router';
import { loader } from '@/utils/signals/loader';
import {
  BuildingScanService,
  BuildingScanShareLinkService,
  BuildingScanViewerDetailDto,
  PointOfInterestService,
} from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';
import { createScanRenderer } from '@wr/scan-renderer';
import { DialogService } from '@services/dialog-service';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { Validators } from '@angular/forms';
import { createForm } from '@sonofabit/ng-core';

@Component({
  selector: 'wr-building-scan-detail-page',
  templateUrl: './building-scan-detail-page.component.html',
  styleUrls: ['./building-scan-detail-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScanDetailPageComponent implements PendingChanges, OnDestroy {

  private readonly buildingScanService = inject(BuildingScanService);

  readonly pointOfInterestService = inject(PointOfInterestService);

  readonly scanId = routeParam('scanId', true);

  readonly loading = loader();

  readonly reloadSignal = signal(0);

  readonly buildingScan = asyncComputed(() => this.buildingScanService.getBuildingScanById(this.scanId()), undefined, this.loading);

  scanViewer?: ReturnType<typeof createScanRenderer>;

  readonly scan = asyncComputed(() => this.buildingScanService.getBuildingScanById(this.scanId()), undefined, this.loading);

  hasPendingChanges(): boolean | Promise<boolean> {
    return false;
  }

  async navigateBack(): Promise<void> {
    window.history.back();
  }

  async deletePointOfInterest(id: string) {
    try {
      await firstValueFrom(this.pointOfInterestService.deletePointOfInterest(id));

      this.scanViewer?.removePointOfInterest(id);
    } catch (e) {
      console.error(e);
    }
  }

  reload() {
    this.reloadSignal.set(this.reloadSignal() + 1);
  }

  ngOnDestroy() {
  }

  readonly dialogService = inject(DialogService);

  async deleteScan() {
    if (await this.dialogService.showDeleteDialog(
      'Scan löschen',
      'Bist du sicher, dass du diesen Scan löschen willst?',
    )) {
      await firstValueFrom(this.buildingScanService.deleteBuildingScan(this.scanId()));
      await this.navigateBack();
    }
  }

  readonly isEditing = signal(false);

  editForm!: ReturnType<typeof this.createForm>;

  private createForm(scan: BuildingScanViewerDetailDto) {
    return createForm(({ field }) => {
      return {
        name: field({
          value: scan.name,
          validators: [Validators.required, Validators.maxLength(128)],
        }),
      };
    });
  }

  editScan() {
    const scan = this.scan();
    if (!scan)
      return;
    this.editForm = this.createForm(scan);
    this.isEditing.set(true);
  }

  isLoading = signal(false);

  async saveScan() {
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid)
      return;

    const scan = this.scan();
    if (!scan)
      return;

    this.isLoading.set(true);
    try {
      const value = await this.editForm.getMappedValue();

      const updated = await firstValueFrom(this.buildingScanService.editBuildingScan(scan.id, value));

      this.scan.update(it => ({ ...it!, name: updated.name }));
    } finally {
      this.isLoading.set(false);
      this.isEditing.set(false);
    }
  }


  cancelEdit() {
    this.editForm.disable();
    this.isEditing.set(false);
  }

  private shareLinkService = inject(BuildingScanShareLinkService);

  createShareLink() {
    const buildingScan = this.buildingScan();
    if (!buildingScan)
      return;

    this.dialogService.showShareLinkDialog({
      createShareLink: (options) => this.shareLinkService.createShareLink({
        id: buildingScan!.id,
        duration: options.durationDays
      })
    });
  }
}
