import { inject, Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { firstValueFrom } from 'rxjs';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import {
  ShareLinkCreateFunction,
  ShareLinkDialogComponent
} from '@components/dialog/share-link-dialog/share-link-dialog.component';

@Injectable()
export class DialogService {
  private readonly dialogService = inject(NbDialogService);

  async showDeleteDialog(title: string, message: string): Promise<boolean> {
    try {
      const dialogRef = this.dialogService.open(DeleteDialogComponent, {
        context: {
          title,
          message,
        },
      });

      return !!await firstValueFrom(dialogRef.onClose);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async showShareLinkDialog(options: ShareLinkDialogOptions): Promise<void> {
    const dialogRef = this.dialogService.open(ShareLinkDialogComponent, {
      context: {
        createShareLink: options.createShareLink,
      },
    });

    return await firstValueFrom(dialogRef.onClose);
  }
}

export interface ShareLinkDialogOptions {
  createShareLink: ShareLinkCreateFunction
}
