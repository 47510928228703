<wr-new-data-table headerText="Tagübersicht"
                   localStorageIdentifier="tag-overview"
                   [loadItemsFunction]="loadTags"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToTag($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-tag
    key="name"
    display="Name">
    <div *ngIf="tag.name">
      {{tag.name}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-tag
    key="color"
    display="Farbe">
    <wr-color-badge [color]="tag.color"></wr-color-badge>
  </ng-template>
  <ng-template
    dataColumn
    let-tag
    key="description"
    display="Beschreibung">
    <div *ngIf="tag.description">
      {{tag.description |  truncate:100}}
    </div>
  </ng-template>
</wr-new-data-table>

