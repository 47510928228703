<wr-grouped-list-view
  [disableAddButton]="!isAdmin()"
  [nbSpinner]="!!(loading$|async)"
  [groups]="repairTasksGrouped()"
  [searchText]="textFilter()"
  (searchTextChange)="textFilter.set($event)"
  (selectedChange)="editRepairTask($event)"
  (addItemClicked)="addRepairTasks()">
  <ng-template wrListItem let-item>
    <wr-repair-task-item [repairTask]="item"
                         (repairTaskDeleted)="inspectionContext.reloadRepairTasks()"
                         [showSendEmailButton]="true"></wr-repair-task-item>
  </ng-template>
  <ng-template wrGroupHeader
               let-id="id"
               let-displayName="displayName"
               let-expanded="expanded"
               let-items="items"
               let-click="click">
    <div class="company-header">
      <span class="name">{{displayName}}</span>
      <button nbButton
              size="small"
              *ngIf="isAdmin()"
              [nbContextMenu]="getStatusContextMenu(items)"
              [nbContextMenuTag]="contextMenuTag">
        <span>Status setzen</span>
      </button>
      <button nbButton
              size="small"
              *ngIf="isAdmin()"
              (click)="showEmailDialog($event,items)">
        <nb-icon pack="eva" icon="email-outline"></nb-icon>
        <span>Email senden</span>
      </button>
      <button nbButton ghost (click)="click(id)">
        <nb-icon pack="eva" [icon]="expanded ? 'chevron-down-outline' : 'chevron-up-outline'"></nb-icon>
      </button>
    </div>
  </ng-template>
</wr-grouped-list-view>
