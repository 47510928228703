<div class="grid">
  <wr-building-dashboard-sensor-card *ngFor="let sensor of sensors"
                                     [icon]="sensor.icon"
                                     [title]="sensor.title"
                                     [style.grid-column]="'span ' + sensor.span"
                                     [connected]="sensor.connected">
    <div class="key-values" *ngIf="sensor.keyValues.length">
      <wr-sensor-value *ngFor="let value of sensor.keyValues"
                       [sensorValue]="value"></wr-sensor-value>
    </div>
    <div class="chart" *ngIf="sensor.chart as chart">
      <wr-chart [dynamicSize]="true"
                [showLegend]="false"
                [labels]="chart!.labels">
        <wr-dataset
          *ngIf="chart!.type === 'line'"
          type="line"
          [data]="chart!.data"
          indexAxis="x">
        </wr-dataset>
        <wr-dataset
          *ngIf="chart!.type === 'bar'"
          type="bar"
          [backgroundColor]="dangerColor"
          [data]="chart!.data"
          indexAxis="x">
        </wr-dataset>
      </wr-chart>
    </div>
  </wr-building-dashboard-sensor-card>
</div>
