<div class="sensor-card">
  <div class="sensor-card-header">
    <nb-icon *ngIf="icon" [config]="icon"></nb-icon>
    <span *ngIf="title" class="title">{{ title }}</span>
    <div class="connection-status" [class.connected]="connected"></div>
  </div>
  <div class="sensor-card-body" [class.disconnected]="!connected">
    <div class="disconnected-icon" *ngIf="!connected">
      <nb-icon icon="alert-triangle-outline" pack="eva"></nb-icon>
    </div>
    <ng-content></ng-content>
  </div>
</div>
