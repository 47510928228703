<wr-new-data-table headerText="Externe Firmenübersicht"
                   localStorageIdentifier="external-user-overview"
                   [loadItemsFunction]="loadContractors"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToContractor($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-contractor
    key="name"
    display="Name">
    <div *ngIf="contractor.name">
      {{contractor.name}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-contractor
    key="description"
    display="Beschreibung">
    <div *ngIf="contractor.description">
      {{contractor.description}}
    </div>
  </ng-template>
</wr-new-data-table>
