import { Component, Input } from '@angular/core';
import { NbIconConfig } from '@nebular/theme';

@Component({
  selector: 'wr-building-dashboard-sensor-card',
  templateUrl: './building-dashboard-sensor-card.component.html',
  styleUrls: ['./building-dashboard-sensor-card.component.scss']
})
export class BuildingDashboardSensorCardComponent {

  @Input() icon?: NbIconConfig;
  @Input() title?: string;
  @Input() connected: boolean = true;
}
