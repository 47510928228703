import { Directive, Input } from '@angular/core';
import { DatasetDirective } from '.';
import { ChartDataset, PointStyle } from 'chart.js/auto';

@Directive({
  selector: 'wr-dataset[type=line][data]',
  providers: [{ provide: DatasetDirective, useExisting: LineDatasetDirective }],
})
export class LineDatasetDirective extends DatasetDirective {
  @Input() backgroundColor?: string;

  @Input() borderColor?: string;

  @Input() yAxisID?: string;

  @Input() xAxisID?: string;

  @Input() indexAxis?: 'x' | 'y';

  @Input() stack?: string;

  @Input() pointStyle?: PointStyle;

  override getDataset(): ChartDataset<'line'> {
    const {
      data,
      label,
      backgroundColor,
      borderColor,
      xAxisID,
      yAxisID,
      indexAxis,
      stack,
      pointStyle,
    } = this;

    return {
      type: 'line',
      label,
      data,
      backgroundColor,
      borderColor,
      xAxisID,
      yAxisID,
      indexAxis,
      stack,
      pointStyle,
    };
  }
}
