import { Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { BuildingState } from '@/store/building/building.state';
import { BuildingDetailDto, BuildingService } from '@artemis-software/wr-api';
import { of } from 'rxjs';
import { select } from '@/utils/signals/select';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-building-dashboard-info',
  templateUrl: './building-dashboard-info.component.html',
  styleUrls: ['./building-dashboard-info.component.scss'],
})
export class BuildingDashboardInfoComponent {

  readonly store = inject(Store);
  readonly buildingService = inject(BuildingService);
  readonly router = inject(Router);

  readonly selectedDashboardBuilding = select(BuildingState.selectedDashboardBuilding);

  readonly buildingDetail = asyncComputed(() => {
    const building = this.selectedDashboardBuilding();
    if (!building) {
      return of(undefined);
    }
    return this.buildingService.findById(building.id);
  });

  getPropertyManager(building: BuildingDetailDto): string {
    const firstName = building.propertyManager?.firstName || building.embeddedPropertyManager?.firstName;
    const lastName = building.propertyManager?.lastName || building.embeddedPropertyManager?.lastName;
    return `${firstName} ${lastName}`;
  }

  editBuilding(building: BuildingDetailDto) {
    this.router.navigate(['building', building.id]);
  }
}
