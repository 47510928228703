<nb-card>
  <nb-card-header>
    Gebäudeinformationen
  </nb-card-header>
  <nb-card-body *ngIf="buildingDetail() as building">
    <div class="detail-body">
      <div class="left">
        <div>
          <label class="label">Name</label>
          <span>{{ building.name }}</span>
        </div>
        <div>
          <label class="label">Gebäudetyp</label>
          <span>{{ building.buildingType|buildingTypeTranslation }}</span>
        </div>
        <div>
          <label class="label">Gebäudeklasse</label>
          <span>{{ building.buildingClass|buildingClassTranslation }}</span>
        </div>
        <div>
          <label class="label">Objektnummer Intern</label>
          <span>{{ building.internalNumber }}</span>
        </div>
        <div>
          <label class="label">Objektnummer Extern</label>
          <span>{{ building.externalNumber }}</span>
        </div>
        <div>
          <label class="label">Genossenschaft</label>
          <span>{{ building.organisation.name }}</span>
        </div>
        <div>
          <label class="label">Hausverwalter</label>
          <span>{{ getPropertyManager(building) }}</span>
        </div>
      </div>
      <div class="right">
        <div>
          <label class="label">Stockwerke</label>
          <span class="number">{{ building.floorCountWithoutCellar }}</span>
        </div>
        <div>
          <label class="label">Anzahl Eingänge</label>
          <span class="number">{{ (building.entryCount) }}</span>
        </div>
        <div>
          <label class="label">Garagen</label>
          <span class="number">{{ building.garageCount }}</span>
        </div>
        <div>
          <label class="label">Tiefgaragen</label>
          <span class="number">{{ building.undergroundGarageCount }}</span>
        </div>
        <div>
          <label class="label">Wohnfläche</label>
          <span class="number">{{ building.area }}m²</span>
        </div>
        <div class="flex-grow"></div>
        <button nbButton fullWidth status="basic" (click)="editBuilding(building)">
          <span>Gebäude bearbeiten</span>
          <nb-icon icon="external-link-outline" pack="eva"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
