import { useInterval } from '@/composables/useInterval';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { Tracked } from '@/utils/signals/tracked';
import { Component, computed, EventEmitter, inject, Input, signal } from '@angular/core';
import { BuildingScanItemDto, BuildingScanService } from '@artemis-software/wr-api';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { PendingChanges } from '@guards/pending-changes.guard';
import { FileUploadService } from '@services/file-upload.service';

@Component({
  selector: 'wr-building-scan-list',
  templateUrl: './building-scan-list.component.html',
  styleUrls: ['./building-scan-list.component.scss'],
})
export class BuildingScanListComponent implements PendingChanges {
  @Input()
  @Tracked()
  buildingId!: string;

  readonly fileUploadService = inject(FileUploadService);
  readonly buildingScanService = inject(BuildingScanService);

  readonly progress = signal(0);

  readonly uploading = signal(false);

  readonly loading = loader();

  constructor() {
    useInterval(() => this.reload(), 10_000);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.uploading();
  }

  readonly scans = asyncComputed(
    () => (this.buildingId ? this.buildingScanService.getBuildingScans(this.buildingId) : []),
    [],
    this.loading,
  );

  readonly pendingScans = asyncComputed(
    () => (this.buildingId ? this.buildingScanService.getImportStatus(this.buildingId) : []),
    [],
    this.loading,
  );

  readonly empty = computed(() => this.scans().length === 0 && this.pendingScans().length === 0);

  reload() {
    this.scans.reload();
    this.pendingScans.reload();
  }

  async onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];

    const fileUploaded = new Subject();

    if (file) {
      const progressEmitter = new EventEmitter<number>();

      progressEmitter.pipe(takeUntil(fileUploaded)).subscribe((progress) => {
        this.progress.set(progress);
      });

      try {
        this.uploading.set(true);
        const artifactKey = await this.fileUploadService.uploadSingleFileAsMultipartFile(file, progressEmitter);
        await firstValueFrom(this.buildingScanService.importScan(this.buildingId, 'Panorama', artifactKey));
        this.reload();
      } finally {
        this.uploading.set(false);
        fileUploaded.next(undefined);
      }
    }
  }

  trackByScan(index: number, scan: BuildingScanItemDto) {
    return scan.id;
  }

  onScanUpdated(scan: BuildingScanItemDto) {
    const index = this.scans().findIndex(s => s.id === scan.id);
    if (index >= 0) {
      const scans = [...this.scans()];
      scans[index] = scan;
      this.scans.set(scans);
    } else {
      this.scans.set([...this.scans(), scan]);
    }
  }
}
