<div class="inspection-entry-list-item"
     [id]="inspectionEntry.value.cid"
     [class.selected]="selected"
     [ngClass]="getInspectionEntryStatusFromFormGroup(inspectionEntry)">
  <div class="number">
    <span>{{ inspectionEntry.controls.checkpointGroupNumber.value }}</span>
    <span>.</span>
    <span>{{ inspectionEntry.controls.checkpointNumber.value }}</span>
  </div>
  <div class="status-icons">
    <div *ngIf="isImminentDanger()" nbTooltip="Gefahr im Verzug" nbTooltipStatus="danger">
      <nb-icon status="danger" icon="alert-triangle-outline" pack="eva"></nb-icon>
    </div>
    <div *ngIf="hasEquipment()" nbTooltip="hat TGA">
      <nb-icon status="info" icon="cube" pack="fas"></nb-icon>
    </div>
    <div *ngIf="inspectionContext.hasRepairTask(inspectionEntry.value!.id!)"
         nbTooltip="hat Reperaturauftrag">
      <nb-icon status="info" icon="screwdriver-wrench" pack="fas"></nb-icon>
    </div>
  </div>
  <div class="name">{{ inspectionEntry.controls.checkpointName.value }}</div>
  <div class="spacer"></div>
  <div class="comments" *ngIf="inspectionEntry.controls.comments.controls.length">
    <nb-icon pack="eva" icon="message-circle-outline"></nb-icon>
    <span>{{ inspectionEntry.controls.comments.controls.length }}</span>
  </div>
</div>
