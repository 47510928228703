import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BuildingState } from '@/store/building/building.state';
import { select } from '@/utils/signals/select';

@Component({
  selector: 'wr-building-dashboard-overview',
  templateUrl: './building-dashboard-overview.component.html',
  styleUrls: ['./building-dashboard-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingDashboardOverviewComponent {

  readonly selectedDashboardBuilding = select(BuildingState.selectedDashboardBuilding);

}
