<nb-card>
  <nb-card-header class="noselect">
    <div class="list-header"
         [class.active]="selectedList() === ListType.TGA"
         (click)="selectedList.set(ListType.TGA)">
      <nb-icon pack="eva" icon="cube-outline"></nb-icon>
      <span>TGAs</span>
    </div>
    <div class="list-header"
         [class.active]="selectedList() === ListType.POI"
         (click)="selectedList.set(ListType.POI)">
      <nb-icon pack="eva" icon="pin-outline"></nb-icon>
      <span>POIs</span>
    </div>
  </nb-card-header>
  <nb-card-body>
    <wr-building-scan-equipment-tree-view *ngIf="selectedList() === ListType.TGA" [readonly]="readonly">
    </wr-building-scan-equipment-tree-view>
    <wr-building-scan-poi-list *ngIf="selectedList() === ListType.POI" [readonly]="readonly">
    </wr-building-scan-poi-list>
  </nb-card-body>
</nb-card>
