import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { BuildingItemDto, BuildingService, OrganisationMergeDto } from '@artemis-software/wr-api';
import { SelectDashboardBuilding } from '@/store/building/building.action';
import { Store } from '@ngxs/store';
import { currentUser } from '@/utils/admin-utils';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import OrganisationTypeEnum = OrganisationMergeDto.OrganisationTypeEnum;
import { watch } from '@/utils/watch';

@Component({
  selector: 'wr-building-dashboard-select',
  templateUrl: './building-dashboard-select.component.html',
  styleUrls: ['./building-dashboard-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingDashboardSelectComponent {
  readonly store = inject(Store);
  readonly buildingService = inject(BuildingService);

  readonly currentUser = currentUser();

  readonly currentOrganisationId = computed(() => {
    if (this.currentUser()?.organisationType == OrganisationTypeEnum.Cooperative) {
      return this.currentUser()?.organisation.id;
    }
    return undefined;
  });

  readonly isPropertyManager = computed(() => this.currentUser()?.organisationType === OrganisationTypeEnum.PropertyManager);

  readonly selectedDashboardBuilding = select(BuildingState.selectedDashboardBuilding);

  readonly editing = signal(false);

  selectDashboardBuilding(building: BuildingItemDto) {
    if (building) {
      this.store.dispatch(new SelectDashboardBuilding(building));
      this.editing.set(false);
    }
  }

  constructor() {
    watch(this.selectedDashboardBuilding, (building) => {
      if (!building) {
        this.editing.set(true);
      }
    });
  }
}
