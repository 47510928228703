import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  CheckpointGroupFilterDto,
  CheckpointGroupItemDto,
  CheckpointGroupService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-checkpoint-group-overview-page',
  templateUrl: './checkpoint-group-overview-page.component.html',
  styleUrls: ['./checkpoint-group-overview-page.component.scss'],
})
export class CheckpointGroupOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadCheckpointGroups = (filter: CheckpointGroupFilterDto) => this.checkpointGroupService.findAll(filter);
  readonly getCount = (filter: CheckpointGroupFilterDto) => this.checkpointGroupService.getCount(filter);

  constructor(private readonly checkpointGroupService: CheckpointGroupService,
    private readonly router: Router,
              private readonly formBuilder: FormBuilder, ) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
    });
  }

  navigateToCheckpointGroup(checkpointGroup: CheckpointGroupItemDto): void {
    this.router.navigate(['checkpointGroup', checkpointGroup.id]);
  }

  add(): void {
    this.router.navigate(['checkpointGroup', 'new']);
  }

  cleanName(name: string): string {
    return name.replace(/_/g, ' ');
  }
}
