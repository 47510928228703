import { ChangeDetectionStrategy, Component, computed, inject, Input, signal } from '@angular/core';
import {
  BuildingEquipmentTreeViewDto,
  MaintenanceTimerTreeViewDto,
  PointOfInterestDto
} from '@artemis-software/wr-api';
import { CreatePointOfInterestOptions, ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';
import { Tracked } from '@/utils/signals/tracked';

@Component({
  selector: 'wr-building-scan-equipment-tree-view-entry',
  templateUrl: './building-scan-equipment-tree-view-entry.component.html',
  styleUrls: ['./building-scan-equipment-tree-view-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScanEquipmentTreeViewEntryComponent {
  @Input()
  @Tracked()
  readonly = false;

  @Input()
  buildingEquipment!: BuildingEquipmentTreeViewDto;

  private readonly scanViewerService = inject(ScanViewerService);

  readonly collapsed = signal(false);

  toggleCollapsed() {
    if (!this.canExpand())
      return;
    this.collapsed.set(!this.collapsed());
  }

  jumpToPointOfInterest(poi: PointOfInterestDto) {
    this.scanViewerService.jumpToPointOfInterest(poi);
  }

  async createPointOfInterestForBuildingEquipment(buildingEquipment: BuildingEquipmentTreeViewDto) {
    await this.createPointOfInterest({
      name: buildingEquipment.equipmentName,
      buildingEquipmentId: buildingEquipment.id,
    });
  }

  async createPointOfInterestForMaintenanceTimer(buildingEquipment: BuildingEquipmentTreeViewDto, maintenanceTimer: MaintenanceTimerTreeViewDto) {
    await this.createPointOfInterest({
      name: buildingEquipment.equipmentName,
      maintenanceTimerId: maintenanceTimer.id,
    });
  }

  async createPointOfInterest(options: Omit<CreatePointOfInterestOptions, 'position' | 'panoramaId'>) {
    const result = await this.scanViewerService.pickPosition();
    if (!result)
      return;

    await this.scanViewerService.createPointOfInterest({
      ...options,
      position: result.point,
      panoramaId: result.panorama.id,
    });
  }

  canExpand = computed(() => {
    if (!this.readonly) return true;

    if (this.buildingEquipment.maintenanceTimers.length > 0)
      return true;

    return this.buildingEquipment.associatedPointsOfInterest.length > 0;
  });
}
