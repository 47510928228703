<ng-container *ngIf="!editing(); else edit">
  <div *ngIf="selectedDashboardBuilding() as building" class="selected-building">
    <span class="section-text">{{ building!.sectionText }}</span>
    <button nbButton status="basic" ghost (click)="editing.set(true)">
      <nb-icon icon="edit-outline" pack="eva"></nb-icon>
    </button>
  </div>
</ng-container>

<ng-template #edit>
  <wr-building-select
    [isPropertyManager]="isPropertyManager()"
    [fixedOrganisationId]="currentOrganisationId()"
    [ngModel]="selectedDashboardBuilding()"
    (ngModelChange)="selectDashboardBuilding($event)"></wr-building-select>
</ng-template>
