import { Component, Input, signal } from '@angular/core';
import { ShareLinkCreateResultDto } from '@artemis-software/wr-api';
import { firstValueFrom, Observable } from 'rxjs';
import { reactive } from '@/utils/signals/reactive';
import { watch } from '@/utils/signals/watch';
import * as Sentry from '@sentry/angular-ivy';

export type ShareLinkCreateFunction = (options: ShareLinkCreateOptions) => Observable<ShareLinkCreateResultDto>

export interface ShareLinkCreateOptions {
  durationDays: number
}

@Component({
  selector: 'wr-share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.scss'],
})
export class ShareLinkDialogComponent {
  readonly options = reactive({
    durationDays: 7
  });

  readonly durations = [1, 7, 30, 90, 365];

  @Input()
  createShareLink!: ShareLinkCreateFunction;

  readonly shareLink = signal<ShareLinkCreateResultDto | null>(null);

  constructor() {
    watch(() => this.options.durationDays, duration => {
      console.log(duration);
    });
  }

  copyToClipboard() {
  }

  openLink() {
  }

  abortController?: AbortController;

  loading = signal(false);

  async generateShareLink() {
    this.abortController?.abort();

    this.shareLink.set(null);

    this.abortController = new AbortController();
    const abortSignal = this.abortController.signal;

    try {
      this.loading.set(true);

      const link = await firstValueFrom(this.createShareLink({ ...this.options }));

      if (!abortSignal.aborted)
        this.shareLink.set(link);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      if (!abortSignal.aborted)
        this.loading.set(false);
    }
  }
}
