import { Component, computed, effect, inject, Input, signal } from '@angular/core';
import { BuildingEquipmentTreeViewDto } from '@artemis-software/wr-api';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';
import { Tracked } from '@/utils/signals/tracked';

@Component({
  selector: 'wr-building-scan-equipment-tree-view',
  templateUrl: './building-scan-equipment-tree-view.component.html',
  styleUrls: ['./building-scan-equipment-tree-view.component.scss'],
})
export class BuildingScanEquipmentTreeViewComponent {
  @Input()
  @Tracked()
  readonly = false;

  readonly trackByFn = (index: number, item: BuildingEquipmentTreeViewDto) => item.id;

  readonly onlyShowWithPOI = signal(false);

  readonly filterText = signal('');

  private readonly scanViewerService = inject(ScanViewerService);

  readonly filteredBuildingEquipments = computed(() => {
    const filter = this.filterText().toLowerCase();

    return this.scanViewerService.buildingEquipments().map((buildingEquipment) => {
      const maintenanceTimers = buildingEquipment.maintenanceTimers.filter(maintenanceTimer => {
        if (this.onlyShowWithPOI() && !maintenanceTimer.associatedPointOfInterest)
          return false;

        return maintenanceTimer.associatedPointOfInterest?.name.toLowerCase().includes(filter);
      });

      if (maintenanceTimers.length === 0) {
        if (this.onlyShowWithPOI() && buildingEquipment.associatedPointsOfInterest.length === 0)
          return null;

        if (buildingEquipment.equipmentName.toLowerCase().includes(filter)
          || buildingEquipment.sectionText.toLowerCase().includes(filter)) {
          return {
            ...buildingEquipment,
            maintenanceTimers,
          };
        } else {
          return null;
        }
      }

      return {
        ...buildingEquipment,
        maintenanceTimers: maintenanceTimers.filter(Boolean),
      };
    }).filter(Boolean) as BuildingEquipmentTreeViewDto[];
  });

  get loading() {
    return this.scanViewerService.buildingEquipmentsLoading();
  }

}
