import { Component } from '@angular/core';
import { NbRouteTab } from '@nebular/theme';

@Component({
  selector: 'wr-building-dashboard-page',
  templateUrl: './building-dashboard-page.component.html',
  styleUrls: ['./building-dashboard-page.component.scss'],
})
export class BuildingDashboardPageComponent {
  readonly tabs: NbRouteTab[] = [
    {
      title: 'Übersicht',
      route: 'overview',
    },
    {
      title: 'Scans',
      route: 'scans',
    },
    {
      title: 'Messwerte',
      route: 'metrics',
    },
  ];
}
