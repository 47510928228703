<nb-card *ngIf="!resource()" class="loading">
  <nb-card-body>
    <nb-spinner *ngIf="isLoading()"></nb-spinner>
  </nb-card-body>
</nb-card>

<ng-container *ngIf="resource()">
  <router-outlet></router-outlet>
</ng-container>

