import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import {
  InspectionDetailDto,
  InspectionItemDto,
  OrganisationItemDto, UserFilterDto, InspectionService,
} from '@artemis-software/wr-api';
import { OrganisationState } from '@/store/organisation/organisation.state';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDateService } from '@nebular/theme';

@Component({
  selector: 'wr-external-inspection-overview-page',
  templateUrl: './external-inspection-overview-page.component.html',
  styleUrls: ['./external-inspection-overview-page.component.scss'],
})
export class ExternalInspectionOverviewPageComponent {
  @Select(OrganisationState.organisations)
  organisations$!: Observable<OrganisationItemDto[]>;

  readonly loadInspections = (filter: UserFilterDto) => this.inspectionService.findAll(filter);
  readonly getCount = (filter: UserFilterDto) => this.inspectionService.getCount(filter);

  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly inspectionService: InspectionService,
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>) {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      organisationId: this.formBuilder.control(undefined),
      continuationNumber: this.formBuilder.control(undefined),
      building: this.formBuilder.control(undefined),
      buildingId: this.formBuilder.control(null),
      status: this.formBuilder.control(InspectionDetailDto.StatusEnum.None),
      selectedRange: new FormControl({
        start: startOfMonth,
        end: endOfMonth,
      }),
      condition: this.formBuilder.control(undefined),
      isPropertyManager: this.formBuilder.control(true),
    });
  }

  getOrganisationName(id: string | undefined): Observable<string | undefined> {
    return this.organisations$.pipe(
      map((organisations: OrganisationItemDto[]) => {
        const dto = organisations.find((organisation: OrganisationItemDto) => organisation.id === id);
        return dto?.name ?? '-';
      }),
    );
  }

  navigateToInspection(entry: InspectionItemDto): void {
    this.router.navigate(['inspection', entry.id]);
  }
}
