/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDetailDto } from './attachmentDetailDto';
import { EquipmentCustomFieldCollectionDetailDto } from './equipmentCustomFieldCollectionDetailDto';


export interface EquipmentDetailDto { 
    id: string;
    name: string;
    description: string;
    maintenanceInterval: EquipmentDetailDto.MaintenanceIntervalEnum;
    equipmentType: EquipmentDetailDto.EquipmentTypeEnum;
    attachments: Array<AttachmentDetailDto>;
    customFieldCollections: Array<EquipmentCustomFieldCollectionDetailDto>;
    icon?: AttachmentDetailDto;
    color: string;
}
export namespace EquipmentDetailDto {
    export type MaintenanceIntervalEnum = 'YEAR' | 'HALF_YEAR' | 'ONE_MONTH' | 'NONE' | 'ONE_DAY';
    export const MaintenanceIntervalEnum = {
        Year: 'YEAR' as MaintenanceIntervalEnum,
        HalfYear: 'HALF_YEAR' as MaintenanceIntervalEnum,
        OneMonth: 'ONE_MONTH' as MaintenanceIntervalEnum,
        None: 'NONE' as MaintenanceIntervalEnum,
        OneDay: 'ONE_DAY' as MaintenanceIntervalEnum
    };
    export type EquipmentTypeEnum = 'UNDEFINED' | 'FIRE_ALARM' | 'SMOKE_DETECTOR' | 'DOOR' | 'WINDOW' | 'FIRE_EXTINGUISHER';
    export const EquipmentTypeEnum = {
        Undefined: 'UNDEFINED' as EquipmentTypeEnum,
        FireAlarm: 'FIRE_ALARM' as EquipmentTypeEnum,
        SmokeDetector: 'SMOKE_DETECTOR' as EquipmentTypeEnum,
        Door: 'DOOR' as EquipmentTypeEnum,
        Window: 'WINDOW' as EquipmentTypeEnum,
        FireExtinguisher: 'FIRE_EXTINGUISHER' as EquipmentTypeEnum
    };
}


