import { Component, computed, HostListener, inject, Input } from '@angular/core';
import { PointOfInterestDto } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';
import { NbDialogService, NbMenuItem, NbMenuService } from '@nebular/theme';
import {
  PoiAttachmentDialogComponent
} from '@pages/building-scan-detail-page/building-scan-poi-navigator/building-scan-poi-list/building-scan-poi-list-item/poi-attachment-dialog/poi-attachment-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wr-building-scan-poi-list-item',
  templateUrl: './building-scan-poi-list-item.component.html',
  styleUrls: ['./building-scan-poi-list-item.component.scss']
})
export class BuildingScanPoiListItemComponent {
  @Input()
  @Tracked()
  pointOfInterest!: PointOfInterestDto;

  private readonly scanViewerService = inject(ScanViewerService);

  private readonly dialogService = inject(NbDialogService);

  private readonly menuService = inject(NbMenuService);

  readonly icon = computed(() => {
    if (this.pointOfInterest.buildingEquipmentId)
      return 'cube-outline';
    if (this.pointOfInterest.maintenanceTimerId)
      return 'clock-outline';
    return 'pin-outline';
  });

  jumpToPointOfInterest() {
    this.scanViewerService.jumpToPointOfInterest(this.pointOfInterest);
  }

  hasMenu = computed(() => {
    return !this.pointOfInterest.maintenanceTimerId && !this.pointOfInterest.buildingEquipmentId;
  });

  menuItems: NbMenuItem[] = [
    {
      title: 'Anhänge bearbeiten',
      icon: {
        icon: 'edit-outline',
        pack: 'eva',
      },
      data: 'edit-attachments'
    }
  ];

  get menuTag() {
    return this.pointOfInterest.id + 'menu';
  }

  constructor() {
    this.menuService.onItemClick()
      .pipe(takeUntilDestroyed())
      .subscribe(it => {
        if (it.tag === this.menuTag)
          this.menuItemSelected(it.item);
      });
  }

  menuItemSelected(item: NbMenuItem) {
    switch (item.data) {
    case 'edit-attachments':
      this.dialogService.open(PoiAttachmentDialogComponent, {
        context: {
          id: this.pointOfInterest.id
        }
      });
    }
  }
}
