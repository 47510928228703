<wr-new-data-table headerText="Benutzerübersicht"
                   localStorageIdentifier="cooperative-user-overview"
                   [loadItemsFunction]="loadUsers"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToUser($event)"
                   [form]="form">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="abbreviation"
    display="Kürzel">
    <div *ngIf="user.abbreviation">
      {{user.abbreviation}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="userName"
    display="Benutzer">
    <div *ngIf="user.userName">
      {{user.userName}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="firstName"
    display="Vorname">
    <div *ngIf="user.firstName">
      {{user.firstName}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="lastName"
    display="Nachname">
    <div *ngIf="user.lastName">
      {{user.lastName}}
    </div>
  </ng-template>
</wr-new-data-table>
