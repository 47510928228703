import { Component, Input, OnInit, Output } from '@angular/core';
import { InspectionPdfItemDto, InspectionPdfService, } from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom, Observable, throwError, timeout } from 'rxjs';
import { tryWithLoading } from '@/utils/async-utils';
import { FilterInspectionPdfs } from '@/store/inspectionPdf/inspectionPdf.action';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'wr-pdf-share-link-dialog',
  templateUrl: './pdf-share-link-dialog.component.html',
  styleUrls: ['./pdf-share-link-dialog.component.scss'],
})
export class PdfShareLinkDialogComponent implements OnInit{

  @Input()
  inspectionPdf$!: Observable<InspectionPdfItemDto | undefined>;

  @Output()
  loading$ = new BehaviorSubject<boolean>(false);

  durations: number[] = [1, 7, 30, 90, 365];
  selectedDuration: number = 1;
  shareLink: string = '';

  constructor(
    private readonly inspectionPdfService: InspectionPdfService,
    private readonly store: Store) {
  }

  ngOnInit() {
    this.inspectionPdf$.subscribe(inspectionPdf => {
      if (inspectionPdf && inspectionPdf.pdfShareLink) {
        this.shareLink = `${window.location.origin}/pdf/${inspectionPdf.pdfShareLink.id}`;
      }
    });
  }

  async generateShareLink() {
    await tryWithLoading(this.loading$, async () => {
      const inspectionPdf = await firstValueFrom(this.inspectionPdf$);
      if(inspectionPdf) {
        await firstValueFrom(
          this.inspectionPdfService.generateShareLink({
            inspectionPdfId: inspectionPdf.id,
            durationDays: this.selectedDuration,
          }).pipe(
            timeout(30000),
            catchError(error => {
              if (error.name === 'TimeoutError') {
                console.error('Request timed out. Please try again.');
              } else {
                console.error('An error occurred:', error.message);
              }
              return throwError(error);
            }),
          ));
        this.store.dispatch(new FilterInspectionPdfs({ inspectionId: inspectionPdf.inspection.id }));
      } else {
        console.error('Inspection PDF data is not available');
      }
    });
  }

  copyToClipboard(link: string) {
    navigator.clipboard.writeText(link).then(() => {
      console.log('Link copied to clipboard');
    }).catch(err => {
      console.error('Could not copy link: ', err);
    });
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }
}
