import { Component, forwardRef, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Select } from "@ngxs/store";
import { UserState } from "@/store/user/user.state";
import { firstValueFrom, Observable, of } from "rxjs";
import { UserDetailDto, UserService } from "@artemis-software/wr-api";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'wr-inspection-entry-comments',
  templateUrl: './inspection-entry-comment.component.html',
  styleUrls: ['./inspection-entry-comment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InspectionEntryCommentComponent),
      multi: true,
    },
  ],
})
export class InspectionEntryCommentComponent {
  @Input()
  formGroup!: FormGroup;

  @Select(UserState.currentUser)
  currentUser$!: Observable<UserDetailDto | undefined>;

  private createCommentForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(''),
      text: new FormControl(''),
      isChecked: new FormControl(false),
      createdBy: new FormControl(null),
      checkedBy: new FormControl(null),
      created: new FormControl(null),
      lastUpdated: new FormControl(null),
    });
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
  ) {
  }

  get comments(): FormArray {
    return this.formGroup.controls['comments'] as FormArray;
  }

  addComment(): void {
    this.comments.push(this.createCommentForm());
  }

  removeComment(index: number): void {
    this.comments.removeAt(index);
  }

  check(index: number): void {
    this.comments.at(index).get('isChecked')?.setValue(true);
  }

  uncheck(index: number): void {
    this.comments.at(index).get('isChecked')?.setValue(false);
  }

  isChecked(index: number): boolean {
    return this.comments.at(index).get('isChecked')?.value;
  }

  getCreatedBy(index: number): Observable<UserDetailDto | undefined> {
    const user = this.comments.at(index).get('createdBy')?.value;
    if (!user) {
      return this.currentUser$;
    }
    return of(user);
  }

  getCreated(index: number): string | null {
    const createdDateValue = this.comments.at(index).get('created')?.value;

    if (!createdDateValue) {
      return null;
    }

    const date = new Date(createdDateValue);
    if (isNaN(date.getTime())) {
      return null;
    }

    return this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');
  }

  isEdited(index: number): boolean {
    return this.comments.at(index).get('created')?.value !== this.comments.at(index).get('lastUpdated')?.value;
  }

  checkedBy(index: number): UserDetailDto | undefined {
    return this.comments.at(index).get('checkedBy')?.value;
  }

  canEdit(createdBy: UserDetailDto | null, currentUser: UserDetailDto | undefined | null): boolean {
    return createdBy?.id === currentUser?.id || createdBy === null;
  }

  getUserProfile(user: UserDetailDto): string {
    const dummyUser = 'https://i.imgur.com/ujW6tKI.png';
    const attachments = user.attachments;
    if (attachments) {
      if (attachments.length > 0) {
        return attachments[0].presignedUrl ?? dummyUser;
      }
    }
    return dummyUser;
  }
}
