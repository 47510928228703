import { Component, inject, Signal } from '@angular/core';
import { shareLinkResource } from '@pages/share-link-page/share-link-page.component';
import { BuildingScanViewerDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-scan-share-link-page',
  templateUrl: './building-scan-share-link-page.component.html',
  styleUrls: ['./building-scan-share-link-page.component.scss'],
})
export class BuildingScanShareLinkPageComponent {
  buildingScan = inject(shareLinkResource) as Signal<BuildingScanViewerDetailDto>;
}
