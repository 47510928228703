import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  OrganisationFilterDto,
  OrganisationItemDto,
  OrganisationService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-organisation-overview-page',
  templateUrl: './organisation-overview-page.component.html',
  styleUrls: ['./organisation-overview-page.component.scss'],
})
export class OrganisationOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadOrganisations = (filter: OrganisationFilterDto) => this.organisationService.findAllDetailDtos(filter);
  readonly getCount = (filter: OrganisationFilterDto) => this.organisationService.getCount(filter);

  constructor(private readonly router: Router,
              private readonly organisationService: OrganisationService,
              private readonly formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
    });
  }

  navigateToOrganisation(organisation: OrganisationItemDto): void {
    this.router.navigate(['organisation', organisation.id]);
  }

  add(): void {
    this.router.navigate(['organisation', 'new']);
  }
}
