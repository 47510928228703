<div class="building-scan-detail-page">
  <wr-full-screen-panel [buttons]="false">
    <nb-card [nbSpinner]="loading()">
      <nb-card-header>
        <button nbButton ghost status="primary" (click)="navigateBack()">
          <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
        </button>

        <div class="title">
          <ng-container *ngIf="!isEditing()">
            <h5>
              {{ scan()?.name }}
              <button nbButton ghost (click)="editScan()">
                <nb-icon icon="edit-outline" pack="eva"></nb-icon>
              </button>
            </h5>

          </ng-container>
          <ng-container *ngIf="isEditing()">
            <hau-form [formGroup]="editForm">
              <div class="title-form">
                <hau-form-field>
                  <input nbInput type="text" formControlName="name">
                </hau-form-field>
                <button nbButton status="primary" (click)="saveScan()">
                  <nb-icon pack="eva" icon="save-outline"></nb-icon>
                </button>
                <button nbButton ghost (click)="cancelEdit()">
                  <nb-icon pack="eva" icon="close-outline"></nb-icon>
                </button>
              </div>
            </hau-form>
          </ng-container>
        </div>
        <button nbButton class="delete-button" ghost status="primary" (click)="deleteScan()">
          <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
        </button>
        <button nbButton ghost (click)="createShareLink()">
          <nb-icon pack="eva" icon="share-outline"></nb-icon>
        </button>
        <wr-full-screen-button></wr-full-screen-button>
      </nb-card-header>
      <nb-card-body>
        <wr-building-scan-split-view *ngIf="buildingScan() as scan" [scan]="scan"></wr-building-scan-split-view>
      </nb-card-body>
    </nb-card>
  </wr-full-screen-panel>
</div>
