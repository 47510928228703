import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserFilterDto, UserItemDto, UserService, } from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-user-overview-page',
  styleUrls: ['./user-overview-page.component.scss'],
  templateUrl: './user-overview-page.component.html',
})
export class UserOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadUsers = (filter: UserFilterDto) => this.userService.findAll(filter);
  readonly getCount = (filter: UserFilterDto) => this.userService.getCount(filter);

  constructor(private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      organisationId: this.formBuilder.control(''),
    });
  }

  navigateToUser(user: UserItemDto): void {
    this.router.navigate(['user', user.id]);
  }

  add(): void {
    this.router.navigate(['user', 'new']);
  }
}
