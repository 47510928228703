import { Component, inject, Input } from '@angular/core';
import { getInspectionEntryStatusFromFormGroup, InspectionEntryFormType } from '../../../inspection-util';
import { InspectionEntryMergeDto } from '@artemis-software/wr-api';
import ActionEnum = InspectionEntryMergeDto.ActionEnum;
import { InspectionContext } from '@pages/inspection-detail-page-v2/inspection.context';

@Component({
  selector: 'wr-inspection-entry-list-item[inspectionEntry]',
  templateUrl: './inspection-entry-list-item.component.html',
  styleUrls: ['./inspection-entry-list-item.component.scss'],
})
export class InspectionEntryListItemComponent {

  readonly inspectionContext = inject(InspectionContext);

  @Input()
  selected = false;

  @Input()
  inspectionEntry!: InspectionEntryFormType;

  isImminentDanger(): boolean {
    return this.inspectionEntry.controls.action.value === ActionEnum.ImminentDanger;
  }

  hasEquipment(): boolean {
    return this.inspectionEntry.controls.buildingEquipmentRelated.value;
  }

  protected readonly getInspectionEntryStatusFromFormGroup = getInspectionEntryStatusFromFormGroup;
}
