import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { EventEmitter, inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Configuration, FileUploadService as ApiFileUploadService, Part } from '@artemis-software/wr-api';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';

type UploadSuccess = { key: string; publicUrl: string };

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  readonly service = inject(ApiFileUploadService);

  constructor(private readonly http: HttpClient,
              private readonly apiConfig: Configuration) {
  }

  uploadSingleFile(file: File, onProgress?: (value: number) => void, onError?: (value: HttpResponse<any>) => void, onDone?: (value: HttpResponse<any>) => void): void {
    const formData: FormData = new FormData();
    formData.append('file', file);

    this.http.post(this.apiConfig.basePath + '/api/file/upload',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }).pipe(tap((event: any) => {
      if (event && event.total && event.type === HttpEventType.UploadProgress) {
        const progress: number = Math.round(100 * event.loaded / event.total);
        if (onProgress) {
          onProgress(progress);
        }
      }
    })).subscribe(event => {
      if (event instanceof HttpResponse) {
        if (onDone) {
          onDone(event);
        }
      }
    }, error => {
      if (onError) {
        onError(error);
      }
    });
  }

  uploadSingleFileNoProgress(file: File): Observable<UploadSuccess> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<UploadSuccess>(this.apiConfig.basePath + '/api/file/upload', formData);
  }

  async uploadSingleFileAsMultipartFile(file: File, progress: EventEmitter<number>) {
    const uploadComplete = new Subject();
    try {
      const fileSize = file.size;

      const {
        partSize,
        presignedUrls,
        key,
        uploadId,
      } = await firstValueFrom(this.service.requestPresignedMultipartUpload({
        fileSize,
      }));

      const numberOfParts = presignedUrls.length;
      const completedParts: Part[] = [];

      for (let i = 0; i < numberOfParts; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, fileSize);
        const part = file.slice(start, end);
        const url = presignedUrls[i];
        const progressEmitter = new EventEmitter<number>();
        progressEmitter.pipe(takeUntil(uploadComplete)).subscribe((partProgress) => {
          const maxProgressPerPart = 1 / numberOfParts;
          progress.next(i * maxProgressPerPart + (partProgress / numberOfParts));
        });
        const { etag } = await this.uploadPart(part, url, file.type, progressEmitter);
        if (!etag) {
          throw new Error('eTag not found');
        }
        completedParts.push({ partNumber: i + 1, etag });
      }

      console.log(`Uploaded ${numberOfParts} parts`);

      await firstValueFrom(this.service.completeMultipartUpload({
        key,
        uploadId,
        parts: completedParts,
      }));

      return key;
    } finally {
      uploadComplete.next(undefined);
    }
  }

  private async uploadPart(part: Blob, url: string, fileType: string, progressSignal: EventEmitter<number>) {
    return await new Promise<{
      etag: string | undefined
    }>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          progressSignal.next(event.loaded / event.total);
        }
      });

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const etag = xhr.getResponseHeader('Etag');
            resolve({ etag: this.parseETag(etag) });
          } else {
            reject();
          }
        }
      });

      xhr.open('PUT', url);
      xhr.setRequestHeader('Content-Type', fileType);
      xhr.send(part);
    });
  }

  private parseETag(eTagString: string | null) {
    if (!eTagString) {
      throw new Error('eTag not found');
    }
    return JSON.parse(eTagString) as string;
  }
}
