import { Component, Input } from '@angular/core';
import { CheckboxGroup } from '@components/grouped-checkbox-list/grouped-checkbox-list.component';
import { InspectionEntryWithCheckpoint } from '@pages/inspection-detail-page-v2/repair-tasks/repair-tasks.component';
import {
  BuildingItemDto,
  CheckpointService,
  InspectionEntryDetailDto,
  InspectionEntryItemDto,
} from '@artemis-software/wr-api';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { ActionTranslationPipe } from '@shared/pipes/actionTranslation.pipe';
import { firstValueFrom, Subject, switchMap } from 'rxjs';

type BuildingSectionGroup = {
  buildingSectionId: string,
  groups: CheckboxGroup<string>[],
}

@Component({
  selector: 'wr-repair-task-generate',
  templateUrl: './repair-task-generate.component.html',
  styleUrls: ['./repair-task-generate.component.scss'],
})
export class RepairTaskGenerateComponent {

  building!: BuildingItemDto;
  private _selectedSection?: BuildingSectionDetailDto;
  selectedGroups: CheckboxGroup<string>[] = [];
  buildingSectionGroups: BuildingSectionGroup[] = [];


  readonly onSubmit = () => this.getResult();

  private _entries$ = new Subject<InspectionEntryItemDto[]>();

  actionTranslationPipe = new ActionTranslationPipe();

  constructor(private readonly checkpointService: CheckpointService) {
    this._entries$.pipe(switchMap((items) => {
      return this.mapCheckpointInformation(items);
    })).subscribe(async (entries) => {
      entries.forEach(entry => {
        const buildingSectionId = entry.buildingSectionId;
        const buildingSectionGroup = this.findOrCreateBuildingSectionGroup(buildingSectionId);
        const group = this.findOrCreateCheckboxGroup(buildingSectionGroup, this.getGroupName(entry));

        const item = {
          name: this.getCheckpointName(entry),
          checked: false,
          item: entry.id,
          icon: this.getIconForEntry(entry),
        };

        group.items.push(item);
      });
    });
  }

  @Input()
  set entries(entries: InspectionEntryItemDto[]) {
    this._entries$.next(entries);
  }

  private findOrCreateBuildingSectionGroup(buildingSectionId: string) {
    let buildingSectionGroup = this.buildingSectionGroups.find(b => b.buildingSectionId === buildingSectionId);
    if (!buildingSectionGroup) {
      buildingSectionGroup = {
        buildingSectionId,
        groups: [],
      };
      this.buildingSectionGroups.push(buildingSectionGroup);
    }
    return buildingSectionGroup;
  }

  private findOrCreateCheckboxGroup(buildingSectionGroup: BuildingSectionGroup, groupName: string) {
    let group = buildingSectionGroup.groups.find(g => g.name === groupName);
    if (!group) {
      group = {
        name: groupName,
        checked: false,
        items: [],
      };
      buildingSectionGroup.groups.push(group);
    }
    return group;
  }

  getResult(): string[] {
    const inspectionEntryIds = this.buildingSectionGroups.flatMap((buildingSectionGroup) =>
      buildingSectionGroup.groups.flatMap((group) => group.items
        .filter((item) => item.checked)
        .map((item) => item.item)));
    return inspectionEntryIds;
  }

  private getGroupName(entry: InspectionEntryWithCheckpoint): string {
    return `${entry.checkpoint.checkpointGroup.number} ${entry.checkpoint.checkpointGroup.name}`;
  }

  private getCheckpointName(entry: InspectionEntryWithCheckpoint): string {
    return `${entry.checkpoint.checkpointGroup.number}.${entry.checkpoint.number} ${entry.checkpoint.name}`;
  }

  getBuildingSectionGroup(buildingSection: BuildingSectionDetailDto | undefined): CheckboxGroup<string>[] {
    if (!buildingSection) return [];
    return this.buildingSectionGroups.find((buildingSectionGroup) =>
      buildingSectionGroup.buildingSectionId === buildingSection.id)?.groups ?? [];
  }

  get selectedSection(): BuildingSectionDetailDto | undefined {
    return this._selectedSection;
  }

  set selectedSection(value: BuildingSectionDetailDto | undefined) {
    this._selectedSection = value;
    this.selectedGroups = this.getBuildingSectionGroup(value);
  }

  getIconForEntry(entry: InspectionEntryWithCheckpoint): any {
    const isCriticalAction =
      [InspectionEntryDetailDto.ActionEnum.ActionNecessary,
        InspectionEntryDetailDto.ActionEnum.RenovationNecessary,
        InspectionEntryDetailDto.ActionEnum.ImminentDanger].includes(entry.action!);

    const iconName = isCriticalAction ? 'alert-triangle-outline' : 'info-outline';
    const status = isCriticalAction ? 'danger' : 'info';
    const tooltip = this.actionTranslationPipe.transform(entry.action!);

    return { name: iconName, pack: 'eva', status, tooltip };
  }

  async mapCheckpointInformation(inspectionEntries: InspectionEntryItemDto[]): Promise<InspectionEntryWithCheckpoint[]> {
    const allCheckpoints = await firstValueFrom(this.checkpointService.findAllDetailDtos({
      size: 1000,
    }));
    return inspectionEntries.map(inspectionEntry => {
      const checkpoint = allCheckpoints.find(checkpoint => checkpoint.id === inspectionEntry.checkpointId)!;
      return {
        ...inspectionEntry,
        checkpoint,
      };
    });
  }
}
