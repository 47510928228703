import { booleanAttribute, Component, Input } from '@angular/core';
import { BuildingScanViewerDetailDto } from '@artemis-software/wr-api';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';

@Component({
  selector: 'wr-building-scan-split-view[scan]',
  templateUrl: './building-scan-split-view.component.html',
  styleUrls: ['./building-scan-split-view.component.scss'],
  providers: [ScanViewerService]
})
export class BuildingScanSplitViewComponent {
  @Input() scan!: BuildingScanViewerDetailDto;

  @Input({ transform: booleanAttribute }) readonly = false;
}
