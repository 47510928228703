import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';
import { Router } from '@angular/router';
import { BuildingScanItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-scan-card',
  templateUrl: './building-scan-card.component.html',
  styleUrls: ['./building-scan-card.component.scss'],
})
export class BuildingScanCardComponent {

  readonly router = inject(Router);

  @Input()
  @Tracked()
  scan!: BuildingScanItemDto;

  @Output()
  updated = new EventEmitter<BuildingScanItemDto>();
}
