import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from '@shared/components/search-bar/search-bar.component';
import { NebularModule } from '@shared/nebular/nebular.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListViewComponent } from '@shared/components/list-view/list-view.component';
import { ListItemDirective } from '@shared/components/list-view/list-item.directive';
import { AttachmentsCarouselComponent } from './attachments-carousel/attachments-carousel.component';
import {
  CustomFieldCollectionPreviewComponent,
} from '@shared/components/custom-field-collection-preview/custom-field-collection-preview.component';
import { CollapsibleAreaComponent } from './collapsible-area/collapsible-area.component';
import { CoverPageCustomizationComponent } from './cover-page-customization/cover-page-customization.component';
import { AttachmentUploadModule } from '@shared/form-controls/attachment-upload/attachment-upload.module';
import { HauFormsModule } from '@sonofabit/ng-core';
import { GroupedListViewComponent } from './grouped-list-view/grouped-list-view.component';
import {
  GroupedListViewHeaderDirective
} from '@shared/components/grouped-list-view/grouped-list-view-header.directive';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { ChartComponent } from '@shared/components/chart/chart.component';
import { BarDatasetDirective } from '@shared/components/chart/datasets/bar';
import { LineDatasetDirective } from '@shared/components/chart/datasets/line';
import { MaintenanceTimerItemCardComponent } from './maintenance-timer-item-card/maintenance-timer-item-card.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LargeChartComponent } from '@shared/components/chart/large-chart.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';


@NgModule({
  declarations: [
    SearchBarComponent,
    ListViewComponent,
    ListItemDirective,
    AttachmentsCarouselComponent,
    CustomFieldCollectionPreviewComponent,
    CollapsibleAreaComponent,
    CoverPageCustomizationComponent,
    GroupedListViewComponent,
    GroupedListViewHeaderDirective,
    MenuComponent,
    ChartComponent,
    LargeChartComponent,
    BarDatasetDirective,
    LineDatasetDirective,
    MaintenanceTimerItemCardComponent,
    ProgressBarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NebularModule,
    AttachmentUploadModule,
    HauFormsModule,
    PipesModule,
  ],
  exports: [
    SearchBarComponent,
    ListViewComponent,
    ListItemDirective,
    AttachmentsCarouselComponent,
    CustomFieldCollectionPreviewComponent,
    CollapsibleAreaComponent,
    CoverPageCustomizationComponent,
    GroupedListViewComponent,
    GroupedListViewHeaderDirective,
    MenuComponent,
    ChartComponent,
    LargeChartComponent,
    BarDatasetDirective,
    LineDatasetDirective,
    MaintenanceTimerItemCardComponent,
    ProgressBarComponent,
  ],
})
export class ComponentsModule {
}
