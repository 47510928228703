import { inject, Injectable, signal } from '@angular/core';
import {
  CheckpointService,
  RepairTaskDetailDto,
  RepairTaskMergeDto,
  RepairTaskService,
} from '@artemis-software/wr-api';
import { ListViewGroup } from '@shared/components/grouped-list-view/grouped-list-view.component';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { firstValueFrom } from 'rxjs';
import {
  RepairTaskEditDialogComponent,
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-edit-dialog/repair-task-edit-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class InspectionContext {
  readonly repairTaskService = inject(RepairTaskService);
  readonly checkpointService = inject(CheckpointService);
  readonly nbToastrService = inject(NbToastrService);
  readonly dialogService = inject(NbDialogService);

  readonly inspectionId = signal<string | undefined>(undefined);

  readonly loading = loader();

  readonly reloadRepairTasksSignal = signal(0);

  readonly repairTasks = asyncComputed(() => {
    this.reloadRepairTasksSignal();
    const id = this.inspectionId();
    if (!id) {
      return [];
    }
    return this.repairTaskService.findAll({
      inspectionId: id,
    });
  }, [], this.loading);

  groupRepairTasksByContractor(repairTasks: RepairTaskDetailDto[]): ListViewGroup<RepairTaskDetailDto>[] {
    const map = new Map<string, ListViewGroup<RepairTaskDetailDto>>();
    for (const repairTask of repairTasks) {
      const contractor = repairTask.contractor;
      const key = contractor?.id ?? 'no-contractor';
      if (!map.has(key)) {
        map.set(key, {
          id: key,
          displayName: contractor?.name ?? 'Nicht zugeordnet',
          items: [],
        });
      }
      map.get(key)!.items.push(repairTask);
    }
    return Array.from(map.values());
  }

  getRepairTaskForEntry(inspectionEntryId: string) {
    return this.repairTasks().find(repairTask => repairTask.inspectionEntry?.id === inspectionEntryId);
  }

  hasRepairTask(inspectionEntryId: string) {
    return !!this.getRepairTaskForEntry(inspectionEntryId);
  }

  reloadRepairTasks() {
    this.reloadRepairTasksSignal.set(this.reloadRepairTasksSignal() + 1);
  }

  async editRepairTask(repairTask: RepairTaskDetailDto, checkpointId: string) {
    const checkpoint = await firstValueFrom(this.checkpointService.findById(checkpointId));
    const ref = this.dialogService.open(RepairTaskEditDialogComponent, {
      autoFocus: false,
    });
    ref.componentRef.instance.initDialog(repairTask, checkpoint.contractors);
    ref.onClose.subscribe(async (repairTaskMergeDto: RepairTaskMergeDto) => {
      if (repairTaskMergeDto) {
        const loader = this.loading.add();
        try {
          await firstValueFrom(this.repairTaskService.merge(repairTaskMergeDto));
        } finally {
          loader.stop();
        }
        this.nbToastrService.success('Reparaturauftrag wurde erfolgreich bearbeitet', 'Bearbeitet');
        this.reloadRepairTasks();
      }
    });
  }
}
