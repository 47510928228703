import type { IPointOfInterest, IScan, ScanProvider } from '@wr/scan-renderer';
import {
  BuildingScanViewerDetailDto,
  PointOfInterestDetailDto,
  PointOfInterestEditDto,
  PointOfInterestService, ShareLinkDtoObject, ShareLinkService,
} from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';
import { DialogService } from '@services/dialog-service';
import { Signal } from '@angular/core';
import { EventEmitter } from '@angular/core';

export class ScanViewerAdapter implements ScanProvider {
  constructor(
    readonly scan: BuildingScanViewerDetailDto,
    readonly pointOfInterestService: PointOfInterestService,
    readonly dialogService: DialogService,
    readonly isReadonly: boolean,
    readonly shareLinkService: ShareLinkService,
    readonly shareLink: Signal<ShareLinkDtoObject> | null,
  ) {
  }

  async loadScan(): Promise<IScan> {
    return {
      id: this.scan.id,
      panoramas: this.scan.panoramas.map(panorama => {
        const { id, transform: pose, previewUrl, imageUrl, chunksX, chunksY, chunks } = panorama;

        return { id, pose, previewUrl, imageUrl, chunksX, chunksY, chunks };
      }),
      pointsOfInterest: this.scan.pointsOfInterest
    };
  }

  private convertPointOfInterest(entity: PointOfInterestDetailDto): IPointOfInterest {
    return {
      id: entity.id,
      name: entity.name,
      position: entity.position,
      panoramaId: entity.panoramaId,
      buildingEquipmentId: entity.buildingEquipment?.id,
      maintenanceTimerId: entity.maintenanceTimer?.id,
    };
  }

  pointOfInterestedChanged = new EventEmitter<PointOfInterestDetailDto>();

  async editPointOfInterest(id: string, options: PointOfInterestEditDto) {
    const result = await firstValueFrom(this.pointOfInterestService.update(id, options));

    this.pointOfInterestedChanged.emit(result);

    return this.convertPointOfInterest(result);
  }

  async getPOIDetails(id: string) {
    const shareLink = this.shareLink?.();
    if (shareLink) {
      return await firstValueFrom(this.shareLinkService.getPointOfInterestById(shareLink.id!, id));
    }

    return await firstValueFrom(this.pointOfInterestService.getById(id));
  }

  async deletePointOfInterest(id: string): Promise<boolean> {
    try {
      if (await this.dialogService.showDeleteDialog(
        'Point of Interest löschen',
        'Bist du sicher, dass du diesen Point of Interest löschen willst?',
      )) {
        await firstValueFrom(this.pointOfInterestService.deletePointOfInterest(id));
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }
}
