<div class="file-container">
  <div class="file-list-header" [class.file-list-header-not-displayed]="isDisabled">
    <div class="button-group" *ngIf="!isDisabled">
      <button nbButton
              status="basic"
              (click)="selectAll()"
              nbTooltip="Alle auswählen"
              nbTooltipStatus="basic">
        <nb-icon icon="done-all-outline" pack="eva"></nb-icon>
      </button>
      <button nbButton
              status="basic"
              (click)="deselectAll()"
              nbTooltip="Keine auswählen"
              nbTooltipStatus="basic">
        <nb-icon icon="close-outline" pack="eva"></nb-icon>
      </button>
    </div>
    <div class="right-section">
      <div class="button-group" *ngIf="!isDisabled">
        <button nbButton
                status="basic"
                [disabled]="!selectedFiles.length"
                nbTooltip="Nach links drehen"
                nbTooltipStatus="basic"
                (click)="rotateSelection(-90)">
          <nb-icon icon="undo-outline" pack="eva"></nb-icon>
        </button>
        <button nbButton
                status="basic"
                [disabled]="!selectedFiles.length"
                nbTooltip="Nach rechts drehen"
                nbTooltipStatus="basic"
                (click)="rotateSelection(90)">
          <nb-icon class="flip-icon" icon="undo-outline" pack="eva"></nb-icon>
        </button>
        <button nbButton
                status="danger"
                [disabled]="!selectedFiles.length"
                nbTooltip="Löschen"
                nbTooltipStatus="danger"
                (click)="deleteFiles()">
          <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="value">
    <div *ngIf="value.length === 0 && isDisabled" class="text-hint">
      <span>Keine angehängten Bilder order Dateien.</span>
    </div>
    <div class="file-list"
         *ngIf="value.length"
         [sortableArray]="value"
         [sortableOptions]="sortableOptions">
      <ng-container *ngFor="let attachment of value; let i=index">
        <wr-attachment-preview [class.filtered-image]="!checkHoveredTag(attachment.tags)"
                               [selected]="isSelected(i)"
                               [isDisabled]="isDisabled"
                               (imageEditClick)="toggleSelect(i)"
                               [attachment]="attachment"
                               (tagsEditClick)="showAttachmentDialog(attachment)"
                               *ngIf="checkSelectedTags(attachment.tags) && !attachment.deleted">
        </wr-attachment-preview>
      </ng-container>
    </div>
  </ng-container>
  <wr-attachment-upload-section *ngIf="!isDisabled"
                                [attachments]="value"
                                [formControlContext]="currentControlContext">
  </wr-attachment-upload-section>
  <div class="tag-filter-list" *ngIf="value && value.length > 0">
    <wr-tag-filter-list [attachments]="value"
                        (hoveredTagChange)="hoveredTagChange($event)"
                        (selectedTagsChange)="onSelectedTagsChange($event)">
    </wr-tag-filter-list>
  </div>
</div>
