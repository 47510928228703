<wr-new-data-table headerText="TGA Typen Übersicht"
                   localStorageIdentifier="equipment-overview"
                   [loadItemsFunction]="loadEquipments"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToEquipment($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input id="textFilter" type="text" nbInput formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="equipmentType">Typ</label>
          <wr-equipment-type-select id="equipmentType" formControlName="equipmentType" [showEmptyOption]="true"></wr-equipment-type-select>
        </div>
        <div class="filter-control-group">
          <label class="label" for="maintenanceInterval">Wartungsintervall</label>
          <wr-check-interval-select id="maintenanceInterval" formControlName="maintenanceInterval" [showEmptyOption]="true"></wr-check-interval-select>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="color"
    display="Farbe">
    <wr-color-badge [color]="building.color"></wr-color-badge>
  </ng-template>
  <ng-template
    dataColumn
    let-equipment
    key="name"
    display="Name">
    <nb-user [name]="equipment.name"
             [picture]="getIcon(equipment)"
              size="medium"></nb-user>
  </ng-template>
  <ng-template
    dataColumn
    let-equipment
    key="equipmentType"
    display="Typ">
    <div *ngIf="equipment.equipmentType">
      {{equipment.equipmentType | equipmentTypeTranslation }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-equipment
    key="maintenanceInterval"
    display="Wartungsintervall">
    <div *ngIf="equipment.maintenanceInterval">
      {{equipment.maintenanceInterval | maintenanceIntervalTranslation }}
    </div>
  </ng-template>
</wr-new-data-table>
