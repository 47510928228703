import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateFilterDto, TemplateItemDto, TemplateService } from '@artemis-software/wr-api';
import { Router } from '@angular/router';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-template-overview-page',
  templateUrl: './template-overview-page.component.html',
  styleUrls: ['./template-overview-page.component.scss']
})
export class TemplateOverviewPageComponent {
  isAdmin = isAdmin();

  readonly loadTemplates = (filter: TemplateFilterDto) => this.templateService.findAll(filter);
  readonly getCount = (filter: TemplateFilterDto) => this.templateService.getCount(filter);

  form!: FormGroup;

  constructor(
    private readonly templateService: TemplateService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
    });
  }

  add(): void {
    this.router.navigate(['template', 'new']);
  }

  navigateToTemplate(event: TemplateItemDto): void {
    this.router.navigate(['template', event.id]);
  }
}
