<div class="building-scan-list">
  <wr-progress-bar *ngIf="uploading()" [value]="progress()"></wr-progress-bar>

  <div class="scan-list">
    <wr-building-scan-pending-card *ngFor="let pending of pendingScans()"
                                   [scan]="pending"></wr-building-scan-pending-card>

    <wr-building-scan-card *ngFor="let scan of scans(); trackBy: trackByScan"
                           [scan]="scan"
                           (updated)="onScanUpdated($event)"
    ></wr-building-scan-card>
  </div>

  <div class="empty-text" *ngIf="empty()">
    <nb-icon icon="slash-outline" pack="eva"></nb-icon>
    <span>Keine Scans vorhanden</span>
  </div>

  <div class="upload-section">
    <button nbButton status="primary" (click)="fileUpload.click()" class="upload-button" [disabled]="uploading()">
      <nb-icon icon="cloud-upload-outline" pack="eva"></nb-icon>
      <div>
        <span class="button-text">3D Scan hochladen</span>
        <span class="file-hint">.e57 Pointcloud</span>
      </div>
    </button>
    <input #fileUpload type="file" accept=".e57" (change)="onFileChange($event)"/>
  </div>
</div>
