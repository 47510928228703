<wr-new-data-table headerText="Kundenübersicht"
                   localStorageIdentifier="organisation-overview"
                   [loadItemsFunction]="loadOrganisations"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToOrganisation($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-organisation
    key="name"
    display="Name">
    <div *ngIf="organisation.name">
      {{organisation.name}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-organisation
    key="paymentAddress"
    display="Straße">
    <div *ngIf="organisation.paymentAddress as address">
      <span>{{address.street}} {{address.number}}</span>
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-organisation
    key="paymentAddress"
    display="PLZ">
    <div *ngIf="organisation.paymentAddress as address">
      {{address.country}}-{{address.zipCode}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-organisation
    key="paymentAddress"
    display="Stadt">
    <div *ngIf="organisation.paymentAddress as address">
      {{address.city}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-organisation
    key="loggedInUsers"
    display="Aktive Benutzer">
    <div>
      {{ organisation.loggedInUsers }} / {{ organisation.license.maxUsers || '-' }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-organisation
    key="organisationType"
    display="Typ">
    <div>
      {{ organisation.organisationType | organisationTypeTranslation }}
    </div>
  </ng-template>
</wr-new-data-table>
