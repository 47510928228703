<nb-alert *ngIf="hasSendingStatus" status="warning">
  <div class="alert-flex">
    <nb-icon icon="alert-triangle-outline" pack="eva"></nb-icon>
    Rundgang wurde bereits versendet und sollte nicht mehr bearbeitet werden.
  </div>
</nb-alert>

<nb-alert *ngIf="willApplyPreviousInspection" status="info">
  <div class="alert-flex">
    <nb-icon icon="info-outline" pack="eva"></nb-icon>
    Früherer Rundgang wird als Vorlage verwendet.
  </div>
</nb-alert>

<div class="detail-container" [nbSpinner]="!!(loading$|async)">
  <nb-card>
    <nb-card-header class="noselect">
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Rundgang</h5>
      <ng-container *ngIf="!lockEdit; else locked">
        <ng-container *ngIf="isAdmin()">
          <button nbButton
                  *ngIf="formWrapper.disabled"
                  size="small"
                  [disabled]="(!(inspection?.status == 'DONE' || inspection?.status == 'SENT'))"
                  (click)="showEmailDialog(inspection!)">
            <nb-icon pack="eva" icon="email-outline"></nb-icon>
            <span>Email senden</span>
          </button>
          <span class="noselect" *ngIf="formWrapper.disabled">|</span>
          <nb-toggle [checked]="autoSaveEnabled()"
                     (checkedChange)="toggleAutosave($event)"
                     status="info"
                     labelPosition="start">
            Automatisch speichern
          </nb-toggle>
          <span class="noselect">|</span>
          <button nbButton
                  *ngIf="formWrapper.disabled"
                  status="basic"
                  nbTooltip="Bearbeiten"
                  (click)="formWrapper.enable()">
            <nb-icon pack="eva" icon="edit-outline"></nb-icon>
          </button>
          <button nbButton
                  *ngIf="formWrapper.enabled"
                  status="danger"
                  nbTooltip="Abbrechen"
                  (click)="cancel()">
            <nb-icon pack="eva" icon="close-outline"></nb-icon>
          </button>
          <button nbButton
                  *ngIf="formWrapper.enabled"
                  status="success"
                  nbTooltip="Speichern"
                  (click)="saveInspection(false)">
            <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
          </button>
          <button nbButton
                  *ngIf="formWrapper.disabled && !isNew"
                  status="danger"
                  (click)="showDeleteDialog()"
                  nbTooltip="Löschen">
            <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
          </button>
        </ng-container>
      </ng-container>
      <ng-template #locked>
        <nb-icon icon="alert-triangle-outline" pack="eva" status="danger"></nb-icon>
        <span>Rundgang wird gerade bearbeitet</span>
        <button nbButton status="danger" size="small" ghost outline (click)="showEditOverrideDialog()">
          Trotzdem bearbeiten
        </button>
      </ng-template>
    </nb-card-header>
    <nb-card-body>
      <nb-tabset *ngIf="formWrapper.form"
                 (changeTab)="changeTab($event)"
                 routeParam="inspectionTab">
        <nb-tab tabTitle="Allgemein"
                [tabId]="general_tab"
                [route]="general_tab">
          <wr-detail-form [formWrapper]="formWrapper">
          </wr-detail-form>
        </nb-tab>
        <nb-tab tabTitle="Prüfpunktzeilen"
                *ngIf="!isNew"
                [tabId]="entries_tab"
                [route]="entries_tab">
          <ng-container *ngIf="inspection">
            <wr-inspection-entries [formWrapper]="formWrapper"
                                   [inspection]="inspection"
                                   (importing$)="importing$.next($event)"
                                   (repairTaskCreated)="inspectionContext.reloadRepairTasks()"
                                   [inspectionEntryId]="inspectionEntryId">
            </wr-inspection-entries>
          </ng-container>
        </nb-tab>
        <nb-tab tabTitle="TGAs" *ngIf="!isNew"
                [tabId]="building_equipments_tab"
                [route]="building_equipments_tab">
          <wr-building-equipments-cockpit [formWrapper]="formWrapper"></wr-building-equipments-cockpit>
        </nb-tab>
        <nb-tab tabTitle="Downloads"
                [tabId]="pdfs_tab"
                *ngIf="formWrapper.disabled && !isNew && inspection"
                [route]="pdfs_tab">
          <wr-inspection-pdfs [inspection]="inspection">
          </wr-inspection-pdfs>
        </nb-tab>
        <nb-tab tabTitle="Reparaturaufträge"
                [tabId]="repair_tasks_tab"
                *ngIf="formWrapper.disabled && !isNew && inspection"
                [route]="repair_tasks_tab">
          <wr-repair-tasks [inspection]="inspection"></wr-repair-tasks>
        </nb-tab>
        <nb-tab tabTitle="Metadata"
                [tabId]="meta_data_tab"
                *ngIf="inspection"
                [route]="meta_data_tab">
          <wr-meta-data [inspection]="inspection" [formWrapper]="formWrapper"></wr-meta-data>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
    <nb-card-footer *ngIf="isAdmin()">
      <label class="last-saved">Zuletzt
        gespeichert: {{ inspection?.lastSavedTimeStamp | date: 'dd.MM.yyyy HH:mm' }}</label>
      <div class="flex-grow"></div>
      <nb-icon *ngIf="hasAnyPendingAttachments"
               icon="sync-outline"
               pack="eva"
               status="danger"
               nbTooltip="Bilder werden hochgeladen"></nb-icon>
      <button [disabled]="formWrapper.disabled" nbButton status="primary" (click)="saveInspection(false)">
        Speichern
      </button>
      <button [disabled]="formWrapper.disabled" nbButton status="basic" (click)="cancel()">Abbrechen</button>
    </nb-card-footer>
  </nb-card>
</div>
