import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExternalContractorService, RepairTaskDetailDto, RepairTaskService } from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { tryWithLoading } from '@/utils/async-utils';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-repair-task-item',
  templateUrl: './repair-task-item.component.html',
  styleUrls: ['./repair-task-item.component.scss'],
})
export class RepairTaskItemComponent {
  isAdmin = isAdmin();

  @Input()
  repairTask!: RepairTaskDetailDto;

  @Output()
  repairTaskDeleted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  repairTaskFinished: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  showDeleteButton = true;

  @Input()
  showConfirmButton = false;

  @Input()
  showSendEmailButton = false;

  @Input()
  showDescription = false;

  loading$ = new BehaviorSubject<boolean>(false);

  private readonly repairTaskStatus = RepairTaskDetailDto.StatusEnum;

  constructor(private readonly externalContractorService: ExternalContractorService,
              private readonly nbDialogService: NbDialogService,
              private readonly nbToastrService: NbToastrService,
              private readonly repairTaskService: RepairTaskService) {
  }

  async showDeleteDialog(event: any) {
    event.stopPropagation();
    if (await firstValueFrom(this.nbDialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Reparaturauftrag löschen',
        message: 'Bist du sicher, dass du diesen Reparaturauftrag löschen willst?',
      },
    }).onClose)) {
      const id = this.repairTask.id;
      await tryWithLoading(this.loading$, async () => firstValueFrom(this.repairTaskService.deleteById(id)));
      this.repairTaskDeleted.emit();
      this.nbToastrService.success('Reparaturauftrag wurde erfolgreich gelöscht', 'Gelöscht');
    }
  }

  setStatusFinished(event: any) {
    event.stopPropagation();
    this.externalContractorService.updateRepairTaskStatus(this.repairTask.id, this.repairTaskStatus.Finished).subscribe(() => {
      this.nbToastrService.success('Reparaturauftrag wurde erfolgreich abgeschlossen', 'Abgeschlossen');
      this.repairTaskFinished.emit();
    });
  }
}
