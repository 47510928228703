import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class FullScreenService {
  isFullScreen = signal(false);

  toggleFullScreen() {
    this.isFullScreen.set(!this.isFullScreen());
  }
}
