import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InspectionPdfService, PdfShareLinkService } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-share-pdf-page',
  templateUrl: './share-pdf-page.component.html',
  styleUrls: ['./share-pdf-page.component.scss'],
})
export class SharePdfPageComponent {
  id: string = '';
  pdfUrl: SafeResourceUrl | undefined;
  pdfLoadFailed: boolean = false;

  constructor(private readonly route: ActivatedRoute,
    private readonly inspectionPdfService: InspectionPdfService,
    private readonly pdfShareLinkService: PdfShareLinkService,
    private readonly sanitizer: DomSanitizer) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id != null) {
        this.id = id;
        this.viewPdf(this.id);
      } else {
        this.pdfLoadFailed = true;
      }
    });
  }

  async viewPdf(shareLink: string): Promise<void> {
    this.pdfShareLinkService.viewPdf(shareLink).subscribe(
      (response) => {
        const blob = new Blob([response as BlobPart], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      },
      (error) => {
        console.error('Error fetching PDF', error);
        this.pdfLoadFailed = true;
      }
    );
  }
}
