import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  getInspectionEntryStatusFromFormGroup,
  getInspectionEntryStatusText,
  InspectionEntryFormType,
} from '../../inspection-util';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';
import { InspectionFormWrapper } from '../../inspection-form';
import {
  MultiAttachmentControlComponent,
} from '@shared/form-controls/attachment-upload/multi-attachment-control/multi-attachment-control.component';
import {
  AssessmentPresetDetailDto,
  BuildingItemDto,
  BuildingSectionItemDto,
  CheckpointService,
  RepairTaskService,
} from '@artemis-software/wr-api';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { InspectionContext } from '@pages/inspection-detail-page-v2/inspection.context';

@Component({
  selector: 'wr-inspection-entry-form[formWrapper]',
  templateUrl: './inspection-entry-form.component.html',
  styleUrls: ['./inspection-entry-form.component.scss'],
})
export class InspectionEntryFormComponent implements OnInit, OnDestroy {

  readonly inspectionContext = inject(InspectionContext);

  @ViewChild(MultiAttachmentControlComponent)
  attachmentUploadSectionComponent?: MultiAttachmentControlComponent;

  @Input()
  entry!: InspectionEntryFormType;
  @Input()
  formWrapper!: InspectionFormWrapper;

  readonly getInspectionEntryStatusText = getInspectionEntryStatusText;
  readonly getInspectionEntryStatusFromFormGroup = getInspectionEntryStatusFromFormGroup;
  readonly destroy$ = new Subject<void>();
  loading$ = new BehaviorSubject<boolean>(false);

  get hasAnyPendingAttachments(): boolean {
    return this.attachmentUploadSectionComponent?.hasAnyPendingAttachments ?? false;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly checkpointService: CheckpointService,
    private readonly dialogService: NbDialogService,
    private readonly nbToastrService: NbToastrService,
    private readonly repairTaskService: RepairTaskService,
  ) {
  }

  ngOnInit(): void {
    this.formWrapper.enabled$.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
    ).subscribe((res) => {
      if (res) {
        this.entry.enable({ emitEvent: false });
      } else {
        this.entry.disable({ emitEvent: false });
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRecommendedDeadline(): AssessmentPresetDetailDto.CheckIntervalEnum | undefined {
    return this.entry.value.predefinedAssessment?.checkInterval;
  }

  getRecommendedAction(): AssessmentPresetDetailDto.ActionEnum | undefined {
    return this.entry.value.predefinedAssessment?.action;
  }

  hasEquipment() {
    return this.entry.value.buildingEquipmentRelated;
  }

  get building(): BuildingItemDto | undefined {
    return this.formWrapper.form?.controls.building.value;
  }

  get buildingSection(): BuildingSectionItemDto | undefined {
    const id = this.entry.controls.buildingSectionId.value;
    return this.building?.sections?.find(s => s.id === id);
  }

  async editRepairTask(inspectionEntryFormType: InspectionEntryFormType): Promise<void> {
    const checkpointId = inspectionEntryFormType.value.checkpointId;

    if (!checkpointId) {
      throw new Error('CheckpointId is missing');
    }

    const inspectionEntryId = inspectionEntryFormType.value.id;

    if (!inspectionEntryId) {
      throw new Error('InspectionEntryId is missing');
    }
    const repairTask = this.inspectionContext.getRepairTaskForEntry(inspectionEntryId);

    if (!repairTask) {
      throw new Error('RepairTask not found');
    }

    return await this.inspectionContext.editRepairTask(repairTask, checkpointId);
  }
}
