import { Component, computed, Input, signal } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'wr-clipboard-button',
  templateUrl: './clipboard-button.component.html',
  styleUrls: ['./clipboard-button.component.scss'],
})
export class ClipboardButtonComponent  {
  @Input()
  @Tracked()
  value?: string | null;

  @Input()
  timeout = 3500;

  disabled = computed(() => this.value === undefined || this.value === null);

  copiedToClipboard = signal(false);

  timeoutId?: ReturnType<typeof setTimeout>;

  async copyToClipboard() {
    try {
      const link = this.value;
      if (!link)
        return;

      await navigator.clipboard.writeText(link);
      this.copiedToClipboard.set(true);

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
      }

      const timeoutId = this.timeoutId = setTimeout(() => {
        this.copiedToClipboard.set(false);

        if (timeoutId === this.timeoutId)
          this.timeoutId = undefined;
      }, this.timeout);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}
