<div class="section-text">
  <nb-icon pack="fas" icon="message"></nb-icon>
  {{ buildingEquipment.sectionText }}
</div>
<dl>
  <dt>
    <nb-icon pack="eva" icon="cube-outline"></nb-icon>
    TGA
  </dt>
  <dd>{{ buildingEquipment.equipmentName }}</dd>
</dl>
<dl>
  <dt>Typ</dt>
  <dd>{{ formattedType }}</dd>
</dl>
<dl>
  <dt>Dauer</dt>
  <dd>{{ maintenanceTimer.durationDays }} Tag{{ maintenanceTimer.durationDays === 1 ? '' : 'e' }}</dd>
</dl>
<dl>
  <dt>Fälligkeitsdatum</dt>
  <dd>
    {{ maintenanceTimer.nextDate | date: 'dd.MM.yyyy' }}
    <div *ngIf="overdue" class="overdue">
      Fällig
    </div>
  </dd>
</dl>
