import { booleanAttribute, Component, computed, ElementRef, inject, Input, signal, ViewChild } from '@angular/core';
import { PointOfInterestDto } from '@artemis-software/wr-api';
import { CreatePointOfInterestOptions, ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';
import { Tracked } from '@/utils/signals/tracked';

@Component({
  selector: 'wr-building-scan-poi-list',
  templateUrl: './building-scan-poi-list.component.html',
  styleUrls: ['./building-scan-poi-list.component.scss'],
})
export class BuildingScanPoiListComponent {
  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  @Input()
  @Tracked()
  readonly = false;

  private readonly scanViewerService = inject(ScanViewerService);

  readonly filterType = signal<('all' | 'buildingEquipment' | 'maintenanceTimer' | 'other')[]>(['all']);

  readonly filterTypes = [
    { value: 'all', label: 'Alle' },
    { value: 'buildingEquipment', label: 'TGA' },
    { value: 'maintenanceTimer', label: 'Wartungstimer' },
    { value: 'other', label: 'Sonstige' },
  ] as const;

  readonly filterText = signal('');

  private getPoiType(poi: PointOfInterestDto) {
    if (poi.buildingEquipmentId)
      return 'buildingEquipment';
    if (poi.maintenanceTimerId)
      return 'maintenanceTimer';
    return 'other';
  }

  readonly filteredPointsOfInterest = computed(() => {
    const term = this.filterText().toLowerCase().trim();

    const filterType = this.filterType()[0];

    return this.scanViewerService.pointsOfInterest()
      .filter(poi => {
        if (filterType !== 'all' && filterType !== this.getPoiType(poi))
          return false;

        if (term.length > 0 && !poi.name.toLowerCase().includes(term))
          return false;

        return true;
      });


  });

  get loading() {
    return this.scanViewerService.pointsOfInterestsLoading();
  }

  readonly isCreatingPointOfInterest = signal(false);

  readonly canCreatePointOfInterest = computed(() => !this.isCreatingPointOfInterest() && !this.readonly);

  readonly creatingPointOfInterest = signal<CreatePointOfInterestOptions | null>(null);

  async beginCreatingPointOfInterest() {
    this.isCreatingPointOfInterest.set(true);
    const result = await this.scanViewerService.pickPosition();
    if (!result) {
      this.isCreatingPointOfInterest.set(false);
      return;
    }

    this.creatingPointOfInterest.set({
      name: 'Point of Interest',
      position: result.point,
      panoramaId: result.panorama.id,
    });

    setTimeout(() => {
      this.input?.nativeElement.focus();
      this.input?.nativeElement.select();
    });
  }

  async finishCreatingPointOfInterest(poi: CreatePointOfInterestOptions) {
    poi.name = poi.name.trim();

    if (poi.name.length === 0)
      poi.name = 'Point of Interest';

    try {
      await this.scanViewerService.createPointOfInterest(poi);
    } finally {
      this.isCreatingPointOfInterest.set(false);
      this.creatingPointOfInterest.set(null);
    }
  }
}
