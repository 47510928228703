<div class="title">
  <nb-icon icon="cube" pack="eva"></nb-icon>
  3D Scan
</div>
<div class="details">
  <nb-spinner *ngIf="loading()" [message]="loadingText()" ></nb-spinner>
  <div class="error" *ngIf="isFailed()">
    <nb-icon icon="alert-triangle-outline" pack="eva"></nb-icon>
    <div>Verarbeitung fehlgeschlagen</div>
  </div>
</div>
