import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditDeleteButtonGroupComponent } from './edit-delete-button-group/edit-delete-button-group.component';
import { DeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import { OrderToggleButtonComponent } from './order-toggle-button/order-toggle-button.component';
import { PaginationButtonGroupComponent } from './pagination-button-group/pagination-button-group.component';
import { PageSizeSelectorComponent } from './page-size-selector/page-size-selector.component';
import { CheckpointDialogComponent } from './dialog/checkpoint-dialog/checkpoint-dialog.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import {
  TemplateSelectionDialogComponent,
} from './dialog/template-selection-dialog/template-selection-dialog.component';
import {
  PreviousInspectionDialogComponent,
} from './dialog/previous-inspection-dialog/previous-inspection-dialog.component';
import { StatusTextComponent } from './status-text/status-text.component';
import { SharedModule } from '@shared/shared.module';
import { PageScrollFabComponent } from './page-scroll-fab/page-scroll-fab.component';
import { ConfirmEditDialogComponent } from './dialog/confirm-edit-dialog/confirm-edit-dialog.component';
import {
  ApplyFromOtherSectionDialogComponent,
} from './dialog/apply-from-other-section-dialog/apply-from-other-section-dialog.component';
import { BaseDialogComponent } from './dialog/base-dialog/base-dialog.component';
import { DuplicateSectionDialogComponent } from './dialog/duplicate-section-dialog/duplicate-section-dialog.component';
import { GroupedCheckboxListComponent } from './grouped-checkbox-list/grouped-checkbox-list.component';
import { BuildingSectionSelectComponent } from './building-section-select/building-section-select.component';
import { ColorBadgeComponent } from './color-badge/color-badge.component';
import { AttachmentDialogComponent } from './dialog/attachment-dialog/attachment-dialog.component';
import { SplitViewComponent } from './split-view/split-view.component';
import { ListDetailSplitViewComponent } from './split-view/list-detail-split-view/list-detail-split-view.component';
import { DetailViewDirective } from './split-view/list-detail-split-view/detail-view.directive';
import { FormElementWrapperComponent } from './form-element-wrapper/form-element-wrapper.component';
import { CheckpointWeightComponent } from './checkpoint-weight/checkpoint-weight.component';
import { BuildingConditionComponent } from './building-condition/building-condition.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataColumnDirective } from './data-table/data-column.directive';
import { HauFormsModule } from '@sonofabit/ng-core';
import { FormActionsComponent } from './form-actions/form-actions.component';
import { RepairTasksComponent } from '@pages/inspection-detail-page-v2/repair-tasks/repair-tasks.component';
import {
  RepairTaskItemComponent
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-item/repair-task-item.component';
import { RepairTaskStatusIconComponent } from './repair-task-status-icon/repair-task-status-icon.component';
import {
  RepairTaskEditDialogComponent
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-edit-dialog/repair-task-edit-dialog.component';
import {
  RepairTaskGenerateComponent
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-generate/repair-task-generate.component';
import { RepairTaskStatusTextComponent } from './repair-task-status-text/repair-task-status-text.component';
import { ListViewComponent } from '@shared/components/list-view/list-view.component';
import { SendEmailComponent } from './dialog/send-email/send-email.component';
import { PageComponent } from './page/page.component';
import { RouterLink } from '@angular/router';
import { TableComponent } from './table/table.component';
import { ColumnDirective } from '@components/table/column.directive';
import { PaginationDirective } from '@components/table/pagination.directive';
import { CoordinateDialogComponent } from './dialog/coordinate-dialog/coordinate-dialog.component';
import { AddressDialogComponent } from '@components/dialog/address-dialog/address-dialog.component';
import { PropertyManagerDialogComponent } from './dialog/property-manager-dialog/property-manager-dialog.component';
import { ShareLinkDialogComponent } from './dialog/share-link-dialog/share-link-dialog.component';
import { InfoDialogComponent } from './dialog/info-dialog/info-dialog.component';
import { CheckDatesDialogComponent } from './dialog/check-dates-dialog/check-dates-dialog.component';
import { BuildingScanListComponent } from './building-scan-list/building-scan-list.component';
import { BuildingScanPendingRowComponent } from '@components/building-scan-list/building-scan-pending-row/building-scan-pending-row.component';
import { BuildingScanCardComponent } from '@components/building-scan-list/building-scan-card/building-scan-card.component';
import { FullScreenPanelComponent } from './full-screen-panel/full-screen-panel.component';
import {
  FullScreenButtonComponent
} from '@components/full-screen-panel/full-screen-button/full-screen-button.component';
import { PdfShareLinkDialogComponent } from '@components/dialog/pdf-share-link-dialog/pdf-share-link-dialog.component';
import { ClipboardButtonComponent } from './clipboard-button/clipboard-button.component';

@NgModule({
  declarations: [
    EditDeleteButtonGroupComponent,
    DeleteDialogComponent,
    OrderToggleButtonComponent,
    PaginationButtonGroupComponent,
    PageSizeSelectorComponent,
    CheckpointDialogComponent,
    ConfirmDialogComponent,
    TemplateSelectionDialogComponent,
    PreviousInspectionDialogComponent,
    StatusTextComponent,
    PageScrollFabComponent,
    ConfirmEditDialogComponent,
    ApplyFromOtherSectionDialogComponent,
    BaseDialogComponent,
    DuplicateSectionDialogComponent,
    GroupedCheckboxListComponent,
    BuildingSectionSelectComponent,
    CheckpointWeightComponent,
    BuildingConditionComponent,
    DataTableComponent,
    DataColumnDirective,
    ColorBadgeComponent,
    AttachmentDialogComponent,
    SplitViewComponent,
    ListDetailSplitViewComponent,
    DetailViewDirective,
    FormElementWrapperComponent,
    FormActionsComponent,
    RepairTasksComponent,
    RepairTaskItemComponent,
    RepairTaskStatusIconComponent,
    RepairTaskEditDialogComponent,
    RepairTaskGenerateComponent,
    RepairTaskStatusTextComponent,
    SendEmailComponent,
    PageComponent,
    TableComponent,
    ColumnDirective,
    PaginationDirective,
    CoordinateDialogComponent,
    AddressDialogComponent,
    ShareLinkDialogComponent,
    PdfShareLinkDialogComponent,
    AddressDialogComponent,
    PropertyManagerDialogComponent,
    InfoDialogComponent,
    CheckDatesDialogComponent,
    BuildingScanListComponent,
    BuildingScanPendingRowComponent,
    BuildingScanCardComponent,
    FullScreenPanelComponent,
    FullScreenButtonComponent,
    ClipboardButtonComponent,
  ],
  exports: [
    EditDeleteButtonGroupComponent,
    OrderToggleButtonComponent,
    PaginationButtonGroupComponent,
    PageSizeSelectorComponent,
    StatusTextComponent,
    PageScrollFabComponent,
    BuildingSectionSelectComponent,
    CheckpointWeightComponent,
    BuildingConditionComponent,
    DataTableComponent,
    DataColumnDirective,
    ColorBadgeComponent,
    SplitViewComponent,
    ListDetailSplitViewComponent,
    DetailViewDirective,
    FormElementWrapperComponent,
    BaseDialogComponent,
    RepairTasksComponent,
    FormActionsComponent,
    ListViewComponent,
    RepairTaskItemComponent,
    TableComponent,
    ColumnDirective,
    PaginationDirective,
    RepairTaskStatusTextComponent,
    PageComponent,
    AddressDialogComponent,
    RepairTaskStatusIconComponent,
    BuildingScanListComponent,
    FullScreenPanelComponent,
    FullScreenButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HauFormsModule,
    RouterLink,
  ],
})
export class ComponentsModule {
}
