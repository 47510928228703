/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BuildingScanImportDto { 
    id: string;
    status: BuildingScanImportDto.StatusEnum;
}
export namespace BuildingScanImportDto {
    export type StatusEnum = 'UPLOADING' | 'PENDING' | 'IN_PROGRESS' | 'FINISHED' | 'FAILED';
    export const StatusEnum = {
        Uploading: 'UPLOADING' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Finished: 'FINISHED' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
}


