import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BuildingItemDto, BuildingSectionService } from '@artemis-software/wr-api';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay } from 'rxjs';
import { InspectionEntryFormType } from '@pages/inspection-detail-page-v2/inspection-util';

@Component({
  selector: 'wr-building-section-select[building]',
  templateUrl: './building-section-select.component.html',
  styleUrls: ['./building-section-select.component.scss'],
})
export class BuildingSectionSelectComponent {

  buildingSections$ = new BehaviorSubject<BuildingSectionDetailDto[]>([]);

  streets$: Observable<string[]>;
  buildingSectionsByStreet$: Observable<BuildingSectionDetailDto[]>;

  selectedStreet$ = new BehaviorSubject<string | undefined>(undefined);

  private _selectedSection?: BuildingSectionDetailDto;

  @Input()
  set selectedSection(value: BuildingSectionDetailDto | null | undefined) {
    this._selectedSection = value ?? undefined;
    if (value?.address?.street) {
      this.selectedStreet$.next(value?.address?.street);
    }
  }

  get selectedSection(): BuildingSectionDetailDto | undefined {
    return this._selectedSection;
  }

  @Output()
  selectedSectionChange = new EventEmitter<BuildingSectionDetailDto | undefined>;

  private _buildingId?: string;

  @Input()
  set building(value: BuildingItemDto) {
    const buildingId = value.id;
    if (this._buildingId !== buildingId) {
      this.buildingSectionService.findAllByBuildingId(buildingId).subscribe(sections => this.buildingSections$.next(sections));
      this._buildingId = buildingId;
    }
  }

  constructor(
    private readonly buildingSectionService: BuildingSectionService,
  ) {
    this.streets$ = this.buildingSections$.pipe(
      map(sections => sections.map(section => section.address.street)),
      map(arr => [...new Set(arr)]),
      shareReplay(1),
    );

    this.buildingSectionsByStreet$ = combineLatest([this.buildingSections$, this.selectedStreet$]).pipe(
      map(([sections, street]) => sections.filter(section => section.address.street === street)),
      shareReplay(1),
    );

    this.selectedStreet$.subscribe((street) => {
      if (this.selectedSection?.address.street !== street) {
        this.selectedSectionChange.next(undefined);
      }
    });
  }

  selectSection(section: BuildingSectionDetailDto): void {
    this._selectedSection = section;
    this.selectedSectionChange.next(section);
  }

  selectStreet(street: string): void {
    this.selectedStreet$.next(street);
  }

  @HostListener('window:keydown.ArrowLeft', ['$event'])
  previousEntry(event: KeyboardEvent) {
    if (this.shouldHandleKey(event)) {
      this.jumpToSection(-1);
      event.preventDefault();
    }
  }

  @HostListener('window:keydown.ArrowRight', ['$event'])
  nextEntry(event: KeyboardEvent) {
    if (this.shouldHandleKey(event)) {
      this.jumpToSection(1);
      event.preventDefault();
    }
  }

  private shouldHandleKey(event: KeyboardEvent): boolean {
    const target = event.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();
    const isInputField = tagName === 'input' || tagName === 'textarea' || tagName === 'select';
    return !isInputField && !target.isContentEditable && !event.repeat;
  }

  private jumpToSection(indexOffset: number) {
    const sections = this.buildingSections$.value;
    const currentEntry = this.selectedSection;
    const currentIndex = sections.findIndex((entry) => entry.id === currentEntry?.id);
    const nextIndex = currentIndex + indexOffset;
    if (nextIndex >= 0 && nextIndex < sections.length) {
      const section = sections[nextIndex];
      this.selectSection(section);
    }
  }
}
