<div class="notification-item" [class.read]="!!notification.readAt">
  <nb-icon [icon]="icon"
           pack="eva"
           [status]="statusMap[notification.variant]"></nb-icon>
  <div class="notification-body">
    <nb-badge *ngIf="!notification.readAt" status="danger" [dotMode]="true"></nb-badge>
    <div class="notification-title">
      <a *ngIf="getUrl() as url; else titleNoLink" [href]="notification.url"
         (click)="markAsRead.emit(notification)">
        {{ notification.title }}
      </a>
      <ng-template #titleNoLink>
        {{ notification.title }}
      </ng-template>
    </div>
    <div class="notification-message">
      {{ notification.message }}
    </div>
    <div class="notification-type">
      <nb-badge [text]="notificationTypeText" status="primary"></nb-badge>
    </div>
    <div class="footer">
      <div class="notification-date"
           [nbTooltip]="(notification.date | date : 'dd.MM.yyyy hh:mm') ?? ''"
           [nbTooltipStatus]="statusMap[notification.variant]">
        {{ timePassed }}
      </div>
      <div class="actions">
        <button *ngIf="!notification.readAt"
                nbButton
                ghost
                size="tiny"
                status="primary"
                (click)="markAsRead.emit(notification)">
          <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
          gelesen
        </button>
      </div>
    </div>
  </div>
</div>
