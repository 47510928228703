<wr-new-data-table headerText="Emails"
                   localStorageIdentifier="email-overview"
                   [loadItemsFunction]="loadEmails"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToEmail($event)"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="datePicker">Zeitraum</label>
          <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                                [showTodayButton]="true"></wr-date-range-picker>
        </div>
        <div class="filter-control-group">
          <label class="label" for="emailType">Typ</label>
          <wr-email-type-select id="emailType" formControlName="emailType">
          </wr-email-type-select>
        </div>
        <div class="filter-control-group">
          <label class="label" for="emailFilter">Empfänger</label>
          <input type="text" nbInput id="emailFilter" formControlName="emailFilter" placeholder="Empfänger...">
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-email
    key="subject"
    display="Betreff">
    <div *ngIf="email.subject">
      {{ email.subject }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-email
    key="emailType"
    display="Email Typ">
    <div *ngIf="email.emailType" [ngSwitch]="email.emailType">
      <ng-container *ngSwitchCase="EmailTypeEnum.Inspection">
        Rundgang
      </ng-container>
      <ng-container *ngSwitchCase="EmailTypeEnum.RepairTask">
        Reparaturaufträge
      </ng-container>
    </div>
  </ng-template>
</wr-new-data-table>
