import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  BuildingFilterDto,
  BuildingItemDto, BuildingService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QrCodeService } from '@services/qr-code-service';
import { isAdmin } from '@/utils/admin-utils';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wr-external-building-overview-page',
  templateUrl: './external-building-overview-page.component.html',
  styleUrls: ['./external-building-overview-page.component.scss'],
})
export class ExternalBuildingOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadBuildings = (filter: BuildingFilterDto) => this.buildingService.findAll(filter);
  readonly getCount = (filter: BuildingFilterDto) => this.buildingService.getCount(filter);

  constructor(
    private readonly buildingService: BuildingService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly qrCodeService: QrCodeService
  ) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      buildingClass: this.formBuilder.control(''),
      buildingType: this.formBuilder.control(''),
      organisationId: this.formBuilder.control(undefined),
    });
  }

  navigateToBuilding(event: BuildingItemDto): void {
    this.router.navigate(['building', event.id]);
  }

  navigateToEquipment(event: Event, id: string): void {
    event.stopPropagation();
    this.router.navigate(['propertyManager', 'buildingEquipments'], { queryParams: { buildingId: id } });
  }

  generateQRCode(event: Event, building: BuildingItemDto): void {
    event.stopPropagation();
    this.qrCodeService.generateBuildingQRCode(building);
  }

  protected readonly toSignal = toSignal;
}
