import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EmailFilterDto, EmailItemDto, EmailService } from '@artemis-software/wr-api';
import { Router } from '@angular/router';
import { NbDateService } from '@nebular/theme';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-email-overview-page',
  templateUrl: './email-overview-page.component.html',
  styleUrls: ['./email-overview-page.component.scss']
})
export class EmailOverviewPageComponent {

  readonly EmailTypeEnum = EmailItemDto.EmailTypeEnum;
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadEmails = (filter: EmailFilterDto) => this.emailService.findMy(filter);
  readonly getCount = (filter: EmailFilterDto) => this.emailService.getMyCount(filter);

  constructor(
    private readonly emailService: EmailService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly dateService: NbDateService<Date>
  ) {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.form = new FormGroup({
      text: this.formBuilder.control(''),
      selectedRange: new FormControl({
        start: startOfMonth,
        end: endOfMonth,
      }),
      emailType: this.formBuilder.control(null),
      emailFilter: this.formBuilder.control(''),
    });
  }

  navigateToEmail(emailItemDto: EmailItemDto) {
    this.router.navigate(['/email', emailItemDto.id]);
  }
}
