<wr-new-data-table headerText="Prüfpunkt Gruppen"
                   localStorageIdentifier="checkpoint-group-overview"
                   [loadItemsFunction]="loadCheckpointGroups"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToCheckpointGroup($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-checkpointGroup
    key="number"
    display="Nummer">
    <div *ngIf="checkpointGroup.number">
      {{checkpointGroup.number}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-checkpointGroup
    key="name"
    display="Gruppenname">
    <div *ngIf="checkpointGroup.name">
      {{cleanName(checkpointGroup.name)}}
    </div>
  </ng-template>
</wr-new-data-table>
