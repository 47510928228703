import { Component } from '@angular/core';
import { TagFilterDto, TagItemDto, TagService } from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-tag-overview-page',
  templateUrl: './tag-overview-page.component.html',
  styleUrls: ['./tag-overview-page.component.scss'],
})
export class TagOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadTags = (filter: TagFilterDto) => this.tagService.findAll(filter);
  readonly getCount = (filter: TagFilterDto) => this.tagService.getCount(filter);

  constructor(
    private readonly tagService: TagService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
    });
  }

  add(): void {
    this.router.navigate(['tag', 'new']);
  }

  navigateToTag(tag: TagItemDto): void {
    this.router.navigate(['tag', tag.id]);
  }
}
