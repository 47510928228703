<wr-new-data-table headerText="Templateübersicht"
                   localStorageIdentifier="template-overview"
                   [loadItemsFunction]="loadTemplates"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToTemplate($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-template
    key="name"
    display="Name">
    <div *ngIf="template.name">
      {{template.name}}
    </div>
  </ng-template>
</wr-new-data-table>
