<wr-new-data-table headerText="Gebäude"
                   localStorageIdentifier="property-manager-building-overview"
                   [loadItemsFunction]="loadBuildings"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToBuilding($event)"
                   [form]="form">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="buildingClass">Gebäude Klasse</label>
          <wr-building-class-select id="buildingClass"
                                    formControlName="buildingClass"
                                    [showEmptyOption]="true">
          </wr-building-class-select>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="address"
    display="Adresse">
    <div *ngIf="building.sectionText">
      {{building.sectionText}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="name"
    display="Name">
    <div *ngIf="building.name">
      {{building.name}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="organisationName"
    display="Genossenschaft">
    <div *ngIf="building.organisationName">
      {{building.organisationName}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="internalNumber"
    display="Interne Nummer">
    <div *ngIf="building.internalNumber">
      {{building.internalNumber}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="buildingCondition.conditionValue"
    display="Zustand"
    [sortable]="false">
    <wr-building-condition *ngIf="building.buildingCondition.conditionValue as condition" [condition]="condition"></wr-building-condition>
  </ng-template>
  <ng-template
    dataColumn
    let-building
    key="actions"
    display="Aktionen"
    [sortable]="false">
    <div class="action-buttons">
      <button nbButton status="info" size="tiny" (click)="navigateToEquipment($event, building.id)" [nbTooltip]="'Gebäudeausstattung'">
        <nb-icon pack="fas" icon="box"></nb-icon>
      </button>
      <button nbButton status="info" size="tiny" (click)="generateQRCode($event, building.id)" [nbTooltip]="'QR-Code'">
        <nb-icon pack="fas" icon="qrcode"></nb-icon>
      </button>
    </div>
  </ng-template>
</wr-new-data-table>
