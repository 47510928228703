import { Component, effect, inject, Input } from '@angular/core';
import { BuildingEquipmentService, BuildingEquipmentTreeViewDto } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { Tracked } from '@/utils/signals/tracked';

@Component({
  selector: 'wr-building-scan-equipment-tree-building-equipment-popover',
  templateUrl: './building-scan-equipment-tree-building-equipment-popover.component.html',
  styleUrls: ['./building-scan-equipment-tree-building-equipment-popover.component.scss']
})
export class BuildingScanEquipmentTreeBuildingEquipmentPopoverComponent {
  @Input()
  buildingEquipment!: BuildingEquipmentTreeViewDto;
}
