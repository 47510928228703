import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  BuildingFilterDto,
  BuildingItemDto,
  BuildingService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QrCodeService } from '@services/qr-code-service';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-building-overview-page',
  templateUrl: './building-overview-page.component.html',
  styleUrls: ['./building-overview-page.component.scss'],
})
export class BuildingOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadBuildings = (filter: BuildingFilterDto) => this.buildingService.findAll(filter);
  readonly getCount = (filter: BuildingFilterDto) => this.buildingService.getCount(filter);

  constructor(private readonly router: Router,
    private readonly buildingService: BuildingService,
    private readonly formBuilder: FormBuilder,
    private readonly qrCodeService: QrCodeService
  ) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      organisationId: this.formBuilder.control<string | null>(null),
      buildingClass: this.formBuilder.control<string | null>(null),
      buildingType: this.formBuilder.control<string | null>(null),
    });
  }

  navigateToBuilding(event: BuildingItemDto): void {
    this.router.navigate(['building', event.id]);
  }

  add(): void {
    this.router.navigate(['building', 'new']);
  }

  navigateToEquipment(event: Event, id: string): void {
    event.stopPropagation();
    this.router.navigate(['/buildingEquipments'], { queryParams: { buildingId: id } });
  }

  generateQRCode(event: Event, building: BuildingItemDto): void {
    event.stopPropagation();
    this.qrCodeService.generateBuildingQRCode(building);
  }
}
