import { Component, effect, inject, InjectionToken, signal, WritableSignal } from '@angular/core';
import { routeParam } from '@/utils/signals/router';
import { ShareLinkDtoObject, ShareLinkService } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { watch } from '@/utils/watch';
import { ActivatedRoute, Router } from '@angular/router';
import { loader } from '@/utils/signals/loader';

export const shareLink = new InjectionToken<WritableSignal<ShareLinkDtoObject>>('shareLink');
export const shareLinkResource = new InjectionToken<WritableSignal<any>>('shareLinkResource');

@Component({
  selector: 'wr-share-link-page',
  templateUrl: './share-link-page.component.html',
  styleUrls: ['./share-link-page.component.scss'],
  providers: [{
    provide: shareLinkResource,
    useFactory: () => signal(null),
  },
  {
    provide: shareLink,
    useFactory: () => signal(null),
  }]
})
export class ShareLinkPageComponent {
  id = routeParam('id', true);

  private readonly shareLinkService = inject(ShareLinkService);

  private readonly route = inject(ActivatedRoute);

  private readonly router = inject(Router);

  readonly isLoading = loader();

  readonly shareLink = asyncComputed(() => this.shareLinkService.getShareLink(this.id()), undefined, this.isLoading);

  readonly resource = inject(shareLinkResource);

  private injectedShareLink = inject(shareLink);

  constructor() {
    watch(this.shareLink, shareLink => {
      if (!shareLink)
        return;

      this.injectedShareLink.set(shareLink);

      switch (shareLink.type) {
      case 'BUILDING_SCAN':
        this.router.navigate(['scan'], { relativeTo: this.route });
      }
    });

    effect(() => this.resource.set(this.shareLink()?.resource), { allowSignalWrites: true });
  }
}
