<wr-new-data-table headerText="TGAs"
                   localStorageIdentifier="property-manager-building-equipment-overview"
                   [loadItemsFunction]="loadBuildingEquipments"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToBuildingEquipment($event)"
                   [form]="form">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="buildingSelect">Gebäude</label>
          <wr-building-select id="buildingSelect"
                              formControlName="building"
                              [organisationId$]="form.get('organisationId')?.valueChanges">
          </wr-building-select>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-buildingEquipment
    key="equipmentName"
    display="TGA">
    <div *ngIf="buildingEquipment.equipmentName">
      {{buildingEquipment.equipmentName}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-buildingEquipment
    key="equipmentType"
    display="Typ">
    <div *ngIf="buildingEquipment.equipmentType">
      {{buildingEquipment.equipmentType | equipmentTypeTranslation}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-buildingEquipment
    key="quantity"
    display="Anzahl">
    <div *ngIf="buildingEquipment.quantity">
      {{buildingEquipment.quantity}}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-buildingEquipment
    key="building"
    display="Gebäudeteil">
    <div *ngIf="buildingEquipment.sectionText">
      {{buildingEquipment.sectionText}}
    </div>
  </ng-template>
</wr-new-data-table>

