<div class="detail-container">
  <nb-card [nbSpinner]="!!(loading$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>TGA Typ</h5>
      <wr-edit-delete-button-group (edit)="onEdit()" (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <nb-tabset>
          <nb-tab tabTitle="Details">
            <hau-form [formGroup]="form">
              <hau-form-field label="Name" [validation]="form.validation.name">
                <input nbInput fullWidth formControlName="name">
              </hau-form-field>
              <hau-form-field label="Farbe" [validation]="form.validation.color" cols="4">
                <wr-color-picker formControlName="color"></wr-color-picker>
              </hau-form-field>
              <hau-form-field label="Typ" [validation]="form.validation.equipmentType" cols="4">
                <wr-equipment-type-select formControlName="equipmentType"></wr-equipment-type-select>
              </hau-form-field>
              <hau-form-field label="Standard Wartungsintervall" [validation]="form.validation.maintenanceInterval" cols="4">
                <wr-check-interval-select formControlName="maintenanceInterval"></wr-check-interval-select>
              </hau-form-field>
              <hau-form-field label="Beschreibung" [validation]="form.validation.description">
                <textarea nbInput fullWidth formControlName="description"></textarea>
              </hau-form-field>
              <hau-form-field label="Icon (optional)" [validation]="form.validation.iconReference" cols="2">
                <wr-single-attachment-control [formControl]="form.controls.iconReference"
                                              type="IMAGE"></wr-single-attachment-control>
              </hau-form-field>
              <hau-form-field label="Bilder & Dokumentenliste" [validation]="form.validation.attachments" cols="10">
                <wr-multi-attachment-control formControlName="attachments"></wr-multi-attachment-control>
              </hau-form-field>
            </hau-form>
          </nb-tab>
          <nb-tab tabTitle="Benutzerdefinierte Felder">
            <hau-form [formGroup]="form">
              <hau-form-field [validation]="form.validation.collectionIds">
                <wr-custom-field-collections-select
                  formControlName="collectionIds"></wr-custom-field-collections-select>
              </hau-form-field>
            </hau-form>
          </nb-tab>
        </nb-tabset>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Abbrechen</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
    </nb-card-footer>
  </nb-card>
</div>
