import { ChangeDetectionStrategy, Component, computed, inject, ViewChild } from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import { BuildingScanService } from '@artemis-software/wr-api';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import { BuildingScanListComponent } from '@components/building-scan-list/building-scan-list.component';

@Component({
  selector: 'wr-building-dashboard-scans',
  templateUrl: './building-dashboard-scans.component.html',
  styleUrls: ['./building-dashboard-scans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingDashboardScansComponent implements PendingChanges {
  readonly service = inject(BuildingScanService);

  readonly selectedDashboardBuilding = select(BuildingState.selectedDashboardBuilding);

  readonly buildingId = computed(() => this.selectedDashboardBuilding()?.id);

  @ViewChild(BuildingScanListComponent)
  scanList?: BuildingScanListComponent;

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.scanList?.hasPendingChanges() ?? false;
  }
}
