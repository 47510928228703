<div class="poi-details" (click)="jumpToPointOfInterest()">
  <nb-icon pack="eva" [icon]="icon()"></nb-icon>
  <div class="poi-name">
    {{ pointOfInterest.name }}
  </div>
</div>

<button nbButton
        *ngIf="hasMenu()"
        size="small"
        ghost
        [nbPopover]="options"
        nbPopoverPlacement="bottom">
  <nb-icon pack="eva" icon="more-horizontal-outline"></nb-icon>
</button>


<ng-template #options>
  <nb-menu [items]="menuItems" [tag]="menuTag"></nb-menu>
</ng-template>
