/* eslint-disable @typescript-eslint/no-explicit-any */
import { signal, WritableSignal } from '@angular/core';

const reactiveProxies = new WeakMap<any, any>;

export type Reactive<T extends object> = T;

const IS_REACTIVE = Symbol('isReactive');
const TO_RAW = Symbol('toRaw');

export function reactive<T extends object>(target: T): Reactive<T> {
  if (isReactive(target))
    return target as Reactive<T>;

  if (reactiveProxies.has(target))
    return reactiveProxies.get(target)!;

  const signals: Record<string, WritableSignal<any>> = {};

  function getSignal(p: string, receiver: boolean) {
    let propertySignal = signals[p];

    if (!propertySignal) {
      let value: unknown = Reflect.get(target, p, receiver);

      if (typeof value === 'object' && value !== null)
        value = reactive(value) as unknown;

      propertySignal = signal(value);
      signals[p] = propertySignal;
    }

    return propertySignal;
  }

  const proxy = new Proxy(target, {
    get(target: T, p: string | symbol, receiver: any): any {
      if (p === IS_REACTIVE)
        return true;
      if (p === TO_RAW)
        return target;

      if (typeof p === 'string') {
        const signal = getSignal(p, receiver);

        return signal();
      }

      return Reflect.get(target, p, receiver);
    },
    set(target: T, p: string | symbol, newValue: any, receiver: any): boolean {

      if (typeof p === 'string') {
        const signal = getSignal(p, receiver);

        signal.set(newValue);

        return true;
      }

      return Reflect.set(target, p, receiver);
    }
  });

  reactiveProxies.set(target, proxy);

  return proxy as any;
}

export function isReactive<T extends object>(target: T): target is Reactive<T> {
  return (target as any)[IS_REACTIVE];
}

export function toRaw<T extends object>(target: T): Reactive<T> {
  return (target as any)[TO_RAW];
}
