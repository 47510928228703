import { Component } from '@angular/core';
import { ContractorFilterDto, ContractorItemDto, ContractorService, UserFilterDto } from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-contractor-overview-page',
  templateUrl: './contractor-overview-page.component.html',
  styleUrls: ['./contractor-overview-page.component.scss']
})
export class ContractorOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadContractors = (filter: ContractorFilterDto) => this.contractorService.findAll(filter);
  readonly getCount = (filter: ContractorFilterDto) => this.contractorService.getCount(filter);

  constructor(private readonly contractorService: ContractorService,
              private readonly formBuilder: FormBuilder,
              private readonly router: Router,
  ) {
    this.form = new FormGroup({
      text: this.formBuilder.control(''),
    });
  }

  navigateToContractor(contractor: ContractorItemDto) {
    this.router.navigate(['contractor', contractor.id]);
  }

  add() {
    this.router.navigate(['contractor', 'new']);
  }
}
