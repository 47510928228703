<div class="section-text">
  <nb-icon pack="fas" icon="message"></nb-icon>
  {{ buildingEquipment.sectionText }}
</div>
<dl>
  <dt>Erstellt am</dt>
  <dd>{{ buildingEquipment.created | date: 'dd.MM.yyyy' }}</dd>
</dl>
<dl>
  <dt>Anzahl</dt>
  <dd>{{ buildingEquipment.quantity }}</dd>
</dl>
<dl>
  <dt>
    <nb-icon pack="fas" icon="hourglass-half"></nb-icon>
    Wartungstimer
  </dt>
  <dd>
    {{ buildingEquipment.maintenanceTimers.length }}
  </dd>
</dl>
<div>
  <div>
    <wr-attachments-carousel [attachments]="buildingEquipment.attachments"></wr-attachments-carousel>
  </div>
</div>
