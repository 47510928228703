<wr-base-dialog status="primary" header="Share Link">
  <div class="flex-container">
    <div class="duration-container">
      <label for="duration-select">Dauer auswählen:</label>
      <nb-select id="duration-select" [(ngModel)]="options.durationDays">
        <nb-option *ngFor="let duration of durations" [value]="duration">{{duration}} Tage</nb-option>
      </nb-select>
    </div>
    <div>
      <button
        (click)="generateShareLink()"
        nbButton
        status="basic"
        nbTooltip="Generiere Share Link">
        GENERIERE SHARE LINK
      </button>
    </div>
  </div>
  <div class="link-container" *ngIf="shareLink() as shareLink">
    <div class="share-link">
      <label for="share-link">Share Link:</label>
      <input nbInput fullWidth id="share-link" type="text" [value]="shareLink.url" readonly>
    </div>
    <wr-clipboard-button [value]="shareLink.url"></wr-clipboard-button>
    <a [href]="shareLink.url" target="_blank" nbButton status="primary" nbTooltip="Link öffnen">
      <nb-icon pack="eva" icon="external-link-outline"></nb-icon>
    </a>
  </div>
</wr-base-dialog>
