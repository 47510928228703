import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';
import { BuildingScanViewerDetailDto } from '@artemis-software/wr-api';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';

@Component({
  selector: 'wr-scan-viewer[scan]',
  templateUrl: './scan-viewer.component.html',
  styleUrls: ['./scan-viewer.component.scss'],
})
export class ScanViewerComponent implements OnInit, OnDestroy {
  @Input()
  @Tracked()
  scan!: BuildingScanViewerDetailDto;

  @Input()
  readonly = false;

  @ViewChild('container', { static: true }) container!: ElementRef;

  private readonly scanViewerService = inject(ScanViewerService, { optional: true }) ?? new ScanViewerService();

  ngOnInit(): void {
    this.scanViewerService.mount(this.container.nativeElement, this.scan, this.readonly);
  }

  ngOnDestroy() {
    this.scanViewerService.unmount();
  }
}
