<wr-new-data-table headerText="Rundgänge"
                   localStorageIdentifier="property-manager-inspection-overview"
                   [loadItemsFunction]="loadInspections"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToInspection($event)"
                   [form]="form">
  <ng-template dataTableFilter>
    <form *ngIf="form" [formGroup]="form" class="filter-form">
      <div>
        <label class="label" for="textFilter">Textfilter</label>
        <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
      </div>
      <div>
        <label class="label" for="datePicker">Zeitraum</label>
        <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                              [showTodayButton]="true"></wr-date-range-picker>
      </div>
      <div>
        <label class="label" for="continuationNumber">Folgenummer</label>
        <input type="text" nbInput id="continuationNumber" min="0" formControlName="continuationNumber"
               placeholder="Folgenummer...">
      </div>
      <div>
        <label class="label" for="building">Gebäude</label>
        <wr-building-select id="building"
                            formControlName="building">
        </wr-building-select>
      </div>
      <div>
        <label class="label">Zustand</label>
        <wr-condition-select id="buildingCondition" formControlName="condition">
        </wr-condition-select>
      </div>
    </form>
  </ng-template>

  <ng-template
    dataColumn
    let-inspection
    key="continuationNumber"
    display="FolgeNr">
    <div *ngIf="inspection.continuationNumber">
      {{ inspection.continuationNumber }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="status"
    display="Status">
    <div *ngIf="inspection.status">
      <wr-status-text [status]="inspection.status"></wr-status-text>
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="date"
    display="Datum">
    <div *ngIf="inspection.fromTimestamp">
      {{ inspection.fromTimestamp | date: 'dd.MM.yyyy' }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="address"
    display="Adresse">
    <div *ngIf="inspection.building.sectionText">
      {{ inspection.building.sectionText }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="technician"
    display="Techniker*in">
    <div *ngIf="inspection.technician.firstName && inspection.technician.lastName">
      {{ inspection.technician.firstName }} {{ inspection.technician.lastName }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="clerk"
    display="Sachbearbeiter*in">
    <div *ngIf="inspection.clerk ; else noClerk">
      {{ inspection.clerk.firstName }} {{ inspection.clerk.lastName }}
    </div>
    <ng-template #noClerk>
      <div>Nicht zugewiesen</div>
    </ng-template>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="organisation"
    display="Genossenschaft">
    <div *ngIf="inspection.building.organisationId">
      {{ getOrganisationName(inspection.building.organisationId) | async }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="condition"
    display="Zustand"
    [sortable]="false">
    <div *ngIf="inspection.condition.conditionValue">
      <wr-building-condition
        [condition]="inspection.condition.conditionValue"></wr-building-condition>
    </div>
  </ng-template>
</wr-new-data-table>
