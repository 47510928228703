import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BuildingEquipmentFilterDto,
  BuildingEquipmentItemDto,
  BuildingEquipmentService, BuildingService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuildingDetailDto } from '@artemis-software/wr-api/model/buildingDetailDto';
import { DataTableComponent } from '@shared/generic/data-table/data-table.component';

@Component({
  selector: 'wr-external-building-equipment-overview-page',
  templateUrl: './external-building-equipment-overview-page.component.html',
  styleUrls: ['./external-building-equipment-overview-page.component.scss'],
})
export class ExternalBuildingEquipmentOverviewPageComponent {
  @ViewChild(DataTableComponent) dataTable!: DataTableComponent<BuildingEquipmentItemDto, BuildingEquipmentFilterDto>;
  form!: FormGroup;

  readonly loadBuildingEquipments = (filter: BuildingEquipmentFilterDto) => this.buildingEquipmentService.findAll(filter);
  readonly getCount = (filter: BuildingEquipmentFilterDto) => this.buildingEquipmentService.getCount(filter);

  constructor(
    private readonly buildingEquipmentService: BuildingEquipmentService,
    private readonly buildingService: BuildingService,
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      buildingId: this.formBuilder.control(''),
      building: this.formBuilder.control(undefined),
    });

    this.form.get('building')?.valueChanges.subscribe((building: BuildingDetailDto) => {
      this.form.get('buildingId')?.setValue(building?.id);
    });

    this.activeRoute.queryParams.subscribe((params) => {
      if (params['buildingId']) {
        this.buildingService.findById(params['buildingId']).subscribe((building) => {
          this.form.get('building')?.setValue(building);
          this.dataTable.onSubmit();
        });
      }
    });
  }

  navigateToBuildingEquipment(buildingEquipment: BuildingEquipmentItemDto): void {
    this.router.navigate(['buildingEquipment', buildingEquipment.id]);
  }
}
