import { inject, Injectable } from '@angular/core';
import { BuildingItemDto, BuildingService } from '@artemis-software/wr-api';
import { joinUrl } from '@/utils/url-utils';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  readonly buildingService = inject(BuildingService);

  generateBuildingQRCode(building: BuildingItemDto): void {
    const url = joinUrl(window.origin, '/contractor-login/');
    this.buildingService.getBuildingQrCode(building.id, url, 'response').subscribe((response) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? contentDisposition.split('=')[1]
        : 'qrCode.png';
      const url = window.URL.createObjectURL(response.body as unknown as Blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    });
  }
}
