import { Component } from '@angular/core';
import { NbIconConfig } from '@nebular/theme';

export type SensorValue = {
  label: string;
  value: number;
  unit?: string;
};

export type SensorData = {
  title: string;
  icon: NbIconConfig;
  keyValues: SensorValue[];
  connected: boolean;
  chart?: SensorChart;
  span: number;
}

export type SensorChart = {
  data: number[];
  labels: string[];
  type: 'line' | 'bar';
}

@Component({
  selector: 'wr-building-dashboard-metrics',
  templateUrl: './building-dashboard-metrics.component.html',
  styleUrls: ['./building-dashboard-metrics.component.scss'],
})
export class BuildingDashboardMetricsComponent {

  dangerColor = '#ff3d71';

  readonly sensors: SensorData[] = [
    {
      title: 'Temperatur Haus 16',
      icon: {
        icon: 'thermometer',
        pack: 'eva',
      },
      keyValues: [],
      connected: true,
      chart: {
        data: [20, 21, 21, 22, 23, 22, 21, 20, 20, 19],
        labels: ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
        type: 'line',
      },
      span: 1,
    },
    {
      title: 'Störungen',
      icon: {
        icon: 'alert-triangle-outline',
        pack: 'eva',
      },
      keyValues: [],
      connected: true,
      chart: {
        data: [0, 0, 2, 0, 0, 1, 0, 0],
        labels: ['1.10', '2.10', '3.10', '4.10', '5.10', '6.10', '7.10', '8.10'],
        type: 'bar',
      },
      span: 1,
    },
    {
      title: 'Aufzug Haus 10',
      icon: {
        icon: 'plug',
        pack: 'fas',
      },
      keyValues: [
        {
          label: 'Zählerstand',
          value: 1020.8,
          unit: 'kWh',
        },
        {
          label: 'Leistung',
          value: 62.7,
          unit: 'W',
        }, {
          label: 'Spannung L1',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L2',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L3',
          value: 236,
          unit: 'V',
        },
      ],
      connected: true,
      span: 2,
    },
    {
      title: 'Wohnraumlüftung Haus 10',
      icon: {
        icon: 'fan',
        pack: 'fas',
      },
      keyValues: [
        {
          label: 'Zählerstand',
          value: 11811.6,
          unit: 'kWh',
        },
        {
          label: 'Leistung',
          value: 850.3,
          unit: 'W',
        }, {
          label: 'Spannung L1',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L2',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L3',
          value: 236,
          unit: 'V',
        },
      ],
      connected: true,
      span: 2,
    }, {
      title: 'Aufzug Haus 14',
      icon: {
        icon: 'plug',
        pack: 'fas',
      },
      keyValues: [
        {
          label: 'Zählerstand',
          value: 1026.4,
          unit: 'kWh',
        },
        {
          label: 'Leistung',
          value: 63.8,
          unit: 'W',
        }, {
          label: 'Spannung L1',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L2',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L3',
          value: 236,
          unit: 'V',
        },
      ],
      connected: true,
      span: 2,
    }, {
      title: 'Wohnraumlüftung Haus 14',
      icon: {
        icon: 'fan',
        pack: 'fas',
      },
      keyValues: [
        {
          label: 'Zählerstand',
          value: 14849.5,
          unit: 'kWh',
        },
        {
          label: 'Leistung',
          value: 1501.4,
          unit: 'W',
        }, {
          label: 'Spannung L1',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L2',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L3',
          value: 236,
          unit: 'V',
        },
      ],
      connected: true,
      span: 2,
    }, {
      title: 'Aufzug Haus 16',
      icon: {
        icon: 'plug',
        pack: 'fas',
      },
      keyValues: [
        {
          label: 'Zählerstand',
          value: 1161,
          unit: 'kWh',
        },
        {
          label: 'Leistung',
          value: 204,
          unit: 'W',
        }, {
          label: 'Spannung L1',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L2',
          value: 236,
          unit: 'V',
        }, {
          label: 'Spannung L3',
          value: 236,
          unit: 'V',
        },
      ],
      connected: true,
      span: 2,
    }, {
      title: 'Wohnraumlüftung Haus 16',
      icon: {
        icon: 'fan',
        pack: 'fas',
      },
      keyValues: [
        {
          label: 'Zählerstand',
          value: 11847.8,
          unit: 'kWh',
        },
        {
          label: 'Leistung',
          value: 954.7,
          unit: 'W',
        }, {
          label: 'Spannung L1',
          value: 235,
          unit: 'V',
        }, {
          label: 'Spannung L2',
          value: 234,
          unit: 'V',
        }, {
          label: 'Spannung L3',
          value: 235,
          unit: 'V',
        },
      ],
      connected: false,
      span: 2,
    },
  ];
}

