import { Component, effect, inject, Input } from '@angular/core';
import { createForm } from '@sonofabit/ng-core';
import { PointOfInterestDetailDto, PointOfInterestService } from '@artemis-software/wr-api';
import { attachmentsFromDetails, attachmentToMerge } from '@shared/form-controls/attachment-upload/attachment-utils';
import { firstValueFrom } from 'rxjs';
import { Tracked } from '@/utils/signals/tracked';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { whenever } from '@/utils/whenever';

@Component({
  selector: 'wr-poi-attachment-dialog',
  templateUrl: './poi-attachment-dialog.component.html',
  styleUrls: ['./poi-attachment-dialog.component.scss']
})
export class PoiAttachmentDialogComponent {
  @Input()
  @Tracked()
  id!: string;

  pointOfInterest = asyncComputed(() => {
    if (!this.id)
      return undefined;
    return this.pointOfInterestService.getById(this.id);
  });

  constructor() {
    whenever(this.pointOfInterest, (entity) =>
      this.form = this.createForm(entity!)
    );

    effect(() => console.log(this.form));
  }

  form?: ReturnType<typeof this.createForm>;

  createForm(pointOfInterest: PointOfInterestDetailDto) {
    return createForm(({ field }) => {
      return {
        attachments: field({
          value: attachmentsFromDetails(pointOfInterest.attachments),
          toDto: (attachments) => attachments.map(it => attachmentToMerge(it)),
        })
      };
    });
  }

  private readonly pointOfInterestService = inject(PointOfInterestService);

  onSubmit = async () => {
    if (!this.form) return;

    const attachments = (await this.form!.getMappedValue()).attachments;

    return await firstValueFrom(this.pointOfInterestService.updateAttachments(this.id, attachments));
  };
}
