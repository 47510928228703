import { Component, Input } from '@angular/core';
import { BuildingEquipmentTreeViewDto, MaintenanceTimerTreeViewDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-scan-equipment-tree-maintenance-timer-popover',
  templateUrl: './building-scan-equipment-tree-maintenance-timer-popover.component.html',
  styleUrls: ['./building-scan-equipment-tree-maintenance-timer-popover.component.scss']
})
export class BuildingScanEquipmentTreeMaintenanceTimerPopoverComponent {
  @Input()
  maintenanceTimer!: MaintenanceTimerTreeViewDto;

  @Input()
  buildingEquipment!: BuildingEquipmentTreeViewDto;

  get formattedType() {
    switch (this.maintenanceTimer.type) {
    case MaintenanceTimerTreeViewDto.TypeEnum.Maintenance:
      return 'Wartung';
    case MaintenanceTimerTreeViewDto.TypeEnum.Inspection:
      return 'Inspektion';
    default:
      return 'Unbekannt';
    }
  }

  get overdue() {
    return new Date(this.maintenanceTimer.nextDate) < new Date();
  }
}
