import { Component, inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import {
  BuildingDetailDto,
  ConditionDto,
  InspectionDetailDto,
  InspectionFilterDto,
  InspectionItemDto,
  InspectionService,
  OrganisationItemDto,
  UserFilterDto,
} from '@artemis-software/wr-api';
import { OrganisationState } from '@/store/organisation/organisation.state';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDateService } from '@nebular/theme';
import { ChangeInspectionFilter } from '@/store/inspection/inspection.action';
import { general_tab } from '@pages/inspection-detail-page-v2/inspection-tab.constants';
import { createSelectedRange } from '@/utils/filter-range-utils';
import { isAdmin } from '@/utils/admin-utils';
import ConditionEnum = ConditionDto.ConditionEnum;

@Component({
  selector: 'wr-inspection-overview-page',
  templateUrl: './inspection-overview-page.component.html',
  styleUrls: ['./inspection-overview-page.component.scss'],
})
export class InspectionOverviewPageComponent {

  readonly inspectionService = inject(InspectionService);

  isAdmin = isAdmin();

  @Select(OrganisationState.organisations)
  organisations$!: Observable<OrganisationItemDto[]>;

  readonly loadInspections = (filter: InspectionFilterDto) => this.inspectionService.findAll(filter)
  readonly getCount = (filter: InspectionFilterDto) => this.inspectionService.getCount(filter);

  form!: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>,
  ) {
    this.initFilter();
    this.triggerFilter();
  }

  initFilter(): void {
    const filter = localStorage.getItem('inspectionFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.addSpecialFields(
        filterValue.organisationId,
        filterValue.building,
        filterValue.status,
        filterValue.technicianId,
        filterValue.clerkId,
        createSelectedRange(filterValue, this.dateService),
        filterValue.condition,
      );
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {

    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(null),
      isPropertyManager: this.formBuilder.control(false),
      continuationNumber: this.formBuilder.control(undefined),
      building: this.formBuilder.control(undefined),
      buildingId: this.formBuilder.control(null),
      technicianReference: this.formBuilder.control(null),
      clerkReference: this.formBuilder.control(null),
      status: this.formBuilder.control(InspectionDetailDto.StatusEnum.None),
      selectedRange: new FormControl(null),
      condition: this.formBuilder.control(undefined),
    });

    this.form.get('organisationId')?.valueChanges.subscribe(() => {
      this.form.get('building')?.setValue(undefined);
    });

    this.form.get('building')?.valueChanges.subscribe((building) => {
      if (building?.id) {
        this.form.get('buildingId')?.setValue(building.id);
      } else {
        this.form.get('buildingId')?.setValue(null);
      }
    });
  }

  async addSpecialFields(organisationId: string, building: BuildingDetailDto, status: string, technicianId: string,
    clerkId: string, selectedRange: {
      start: Date;
      end: Date
    } | undefined, condition: ConditionEnum): Promise<void> {
    this.form.patchValue({
      'organisationId': organisationId,
      'building': building,
      'technicianReference': technicianId,
      'clerkReference': clerkId,
      'selectedRange': selectedRange,
      'condition': condition,
    });
  }

  getOrganisationName(id: string | undefined): Observable<string | undefined> {
    return this.organisations$.pipe(
      map((organisations: OrganisationItemDto[]) => {
        const dto = organisations.find((organisation: OrganisationItemDto) => organisation.id === id);
        return dto?.name ?? '-';
      }),
    );
  }

  navigateToInspection(entry: InspectionItemDto): void {
    this.router.navigate(['inspection', entry.id, general_tab]);
  }

  triggerFilter(): void {
    this.form.controls['buildingId'].setValue(this.form.controls['building'].value?.id);
    const filter: InspectionFilterDto = this.form.value;
    if (filter.selectedRange && !filter.selectedRange?.end) {
      filter.selectedRange.end = filter.selectedRange.start;
    }
    this.store.dispatch(new ChangeInspectionFilter(filter));
    localStorage.setItem('inspectionFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['inspection', 'new']);
  }
}
