/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationLicenseMergeDto } from './organisationLicenseMergeDto';


export interface OrganisationMergeDto { 
    id?: string;
    name?: string;
    contractDate?: string;
    paymentAddressId?: string;
    organisationType?: OrganisationMergeDto.OrganisationTypeEnum;
    license: OrganisationLicenseMergeDto;
}
export namespace OrganisationMergeDto {
    export type OrganisationTypeEnum = 'PROPERTY_MANAGER' | 'COOPERATIVE' | 'ADMIN';
    export const OrganisationTypeEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as OrganisationTypeEnum,
        Cooperative: 'COOPERATIVE' as OrganisationTypeEnum,
        Admin: 'ADMIN' as OrganisationTypeEnum
    };
}


