<div class="search">
  <wr-search-bar [search]="filterText()" (searchChange)="filterText.set($event)"></wr-search-bar>
  <button nbButton ghost [nbPopover]="filterPopover" nbPopoverPlacement="bottom">
    <nb-icon pack="eva" icon="funnel-outline"></nb-icon>
  </button>

</div>
<div [nbSpinner]="loading">
  <wr-building-scan-poi-list-item *ngFor="let poi of filteredPointsOfInterest()" [pointOfInterest]="poi">
  </wr-building-scan-poi-list-item>
</div>
<div class="center-button" *ngIf="!loading && canCreatePointOfInterest()" (click)="beginCreatingPointOfInterest()">
  <button nbButton status="primary">POI hinzufügen</button>
</div>

<form class="poi" *ngIf="creatingPointOfInterest() as poi" (submit)="finishCreatingPointOfInterest(poi)">
  <nb-icon pack="eva" icon="pin-outline"></nb-icon>
  <input #input nbInput type="text" name="name" [(ngModel)]="poi.name" placeholder="Point of Interest">
  <button nbButton type="submit" status="primary">Speichern</button>
</form>

<ng-template #filterPopover>
  <div class="filter-popover">
    <hau-form-field label="Typ">
      <nb-button-group (valueChange)="filterType.set($event)" size="small">
        <button
          *ngFor="let type of filterTypes"
          nbButtonToggle
          [value]="type.value"
          [pressed]="filterType().includes(type.value)">{{ type.label }}
        </button>
      </nb-button-group>
    </hau-form-field>
  </div>
</ng-template>
