import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingDashboardOverviewComponent } from './building-dashboard-overview/building-dashboard-overview.component';
import { BuildingDashboardScansComponent } from './building-dashboard-scans/building-dashboard-scans.component';
import { BuildingDashboardMetricsComponent } from './building-dashboard-metrics/building-dashboard-metrics.component';
import {
  BuildingDashboardBuildingEquipmentsComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-building-equipments/building-dashboard-building-equipments.component';
import {
  BuildingDashboardInfoComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-info/building-dashboard-info.component';
import {
  BuildingDashboardInspectionsComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-inspections/building-dashboard-inspections.component';
import {
  BuildingDashboardMaintenanceComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-maintenance/building-dashboard-maintenance.component';
import {
  BuildingDashboardMapComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-map/building-dashboard-map.component';
import {
  BuildingDashboardRepairTasksComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-repair-tasks/building-dashboard-repair-tasks.component';
import {
  BuildingDashboardStatsComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-stats/building-dashboard-stats.component';
import { BuildingDashboardPageComponent } from '@pages/building-dashboard/building-dashboard-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { SharedModule } from '@shared/shared.module';
import { HauFormsModule } from '@sonofabit/ng-core';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@/notifications/notifications.module';
import {
  BuildingDashboardSelectComponent
} from '@pages/building-dashboard/building-dashboard-select/building-dashboard-select.component';
import { BuildingDashboardSensorCardComponent } from './building-dashboard-metrics/building-dashboard-sensor-card/building-dashboard-sensor-card.component';
import { SensorValueComponent } from './building-dashboard-metrics/sensor-value/sensor-value.component';



@NgModule({
  declarations: [
    BuildingDashboardPageComponent,

    BuildingDashboardSelectComponent,
    BuildingDashboardOverviewComponent,
    BuildingDashboardScansComponent,
    BuildingDashboardMetricsComponent,

    BuildingDashboardBuildingEquipmentsComponent,
    BuildingDashboardInfoComponent,
    BuildingDashboardInspectionsComponent,
    BuildingDashboardMaintenanceComponent,
    BuildingDashboardMapComponent,
    BuildingDashboardRepairTasksComponent,
    BuildingDashboardStatsComponent,
    BuildingDashboardSensorCardComponent,
    SensorValueComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    SharedModule,
    HauFormsModule,
    RouterModule,
    NotificationsModule,
  ],
  exports:[
    BuildingDashboardPageComponent,
    BuildingDashboardOverviewComponent,
    BuildingDashboardScansComponent,
    BuildingDashboardMetricsComponent,
  ]
})
export class BuildingDashboardModule { }
