import { Component, inject } from '@angular/core';
import { FullScreenService } from '@components/full-screen-panel/full-screen.service';

@Component({
  selector: 'wr-full-screen-button',
  templateUrl: './full-screen-button.component.html',
  styleUrls: ['./full-screen-button.component.scss'],
})
export class FullScreenButtonComponent {
  readonly fullScreenService = inject(FullScreenService, { host: true });

  toggleFullScreen() {
    this.fullScreenService?.toggleFullScreen();
  }

  get icon() {
    return this.fullScreenService?.isFullScreen()
      ? 'close-outline'
      : 'expand-outline';
  }
}
