import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  CheckpointFilterDto,
  CheckpointItemDto,
  CheckpointService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-checkpoint-overview-page',
  templateUrl: './checkpoint-overview-page.component.html',
  styleUrls: ['./checkpoint-overview-page.component.scss'],
})

export class CheckpointOverviewPageComponent {
  isAdmin = isAdmin();
  form!: FormGroup;

  readonly loadCheckpoints = (filter: CheckpointFilterDto) => this.checkpointService.findAll(filter);
  readonly getCount = (filter: CheckpointFilterDto) => this.checkpointService.getCount(filter);

  constructor(
    private readonly checkpointService: CheckpointService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      checkpointGroupId: this.formBuilder.control(''),
      deprecated: this.formBuilder.control(''),
    });
  }

  getCheckpointNumber(checkpoint: CheckpointItemDto): string {
    return checkpoint.checkpointGroupNumber + '.' + checkpoint.number;
  }

  getCheckpointGroupName(checkpoint: CheckpointItemDto): string {
    return checkpoint.checkpointGroupName.substring(0, 30) + '';
  }

  navigateToCheckpoint(checkpoint: CheckpointItemDto): void {
    this.router.navigate(['checkpoint', checkpoint.id]);
  }

  add(): void {
    this.router.navigate(['checkpoint', 'new']);
  }
}
